export const Enums = {
    DATE: {
        YEAR_FIRST_DAY: 'first-day-year',
        YEAR_END_DAY: 'end-day-year',
        MONTH_FIRST_DAY: 'first-day-month',
        MONTH_END_DAY: 'end-day-month',
        YEAR: 'year',
        MONTH: 'month',
        DAY: 'day',
        HOUR: 'hour',
        MINUTE: 'minute',
        SECOND: 'second',
    },
    STATUS: {
        INFO: 0,
        ERROR: 1,
        SUCCESS: 2,
        WARNING: 3,
    },
    LOGIN_TYPE: {
        NULL: 0,
        USER: 1,
        CUSTOMER: 2,
    },
    THEME: {
        NULL: 0,
        EMPTY: 1,
        CLASSIC: 2,
        CUSTOMER: 3,
    },
    YETKI: {
        GENEL_AYAR: 1,

        ROL_LISTELE: 2,
        ROL_ISLEM: 3,
        ROL_SIL: 4,

        PERSONEL_LISTELE: 5,
        PERSONEL_ISLEM: 6,
        PERSONEL_SIL: 7,

        RANDEVU_LISTELE: 8,
        RANDEVU_ISLEM: 9,
        RANDEVU_SIL: 10,

        GOREV_LISTELE: 11,
        GOREV_ISLEM: 12,
        GOREV_SIL: 13,
        GOREV_ATA: 14,

        MUVEKKIL_LISTELE: 15,
        MUVEKKIL_ISLEM: 16,
        MUVEKKIL_SIL: 17,

        MUVEKKILTALEP_LISTELE: 18,
        MUVEKKILTALEP_ISLEM: 19,
        MUVEKKILTALEP_SIL: 20,

        DOSYA_LISTELE: 21,
        DOSYA_ISLEM: 22,
        DOSYA_SIL: 23,

        DURUSMA_LISTELE: 24,
        DURUSMA_ISLEM: 25,
        DURUSMA_SIL: 26,

        MUHASEBE_LISTELE: 27,
        MUHASEBE_ISLEM: 28,
        MUHASEBE_SIL: 29,

        DOSYAEVRAK_LISTELE: 30,
        DOSYAEVRAK_ISLEM: 31,
        DOSYAEVRAK_SIL: 32,
    },
    MIMETYPE: [
        {
            Group: "image",
            Extension: ".jpg",
            MimeType: ["image/jpeg", "image/jpg"],
        },
        {
            Group: "image",
            Extension: ".png",
            MimeType: ["image/png"],
        },
        {
            Group: "image",
            Extension: ".webp",
            MimeType: ["image/webp"],
        },
        {
            Group: "image",
            Extension: ".gif",
            MimeType: ["image/gif"],
        },
        {
            Group: "video",
            Extension: ".mp4",
            MimeType: ["video/mp4", "application/mp4"],
        },
        {
            Group: "video",
            Extension: ".mpeg",
            MimeType: ["video/mpeg"],
        },
        {
            Group: "video",
            Extension: ".avi",
            MimeType: ["video/x-msvideo"],
        },
        {
            Group: "video",
            Extension: ".flv",
            MimeType: ["video/x-flv"],
        },
        {
            Group: "video",
            Extension: ".webm",
            MimeType: ["video/webm"],
        },
        {
            Group: "audio",
            Extension: ".mp3",
            MimeType: ["audio/mpeg3", "audio/x-mpeg-3", "audio/mp3"],
        },
        {
            Group: "audio",
            Extension: ".wav",
            MimeType: ["audio/x-wav"],
        },
        {
            Group: "audio",
            Extension: ".wma",
            MimeType: ["audio/x-ms-wma"],
        },
        {
            Group: "document",
            Extension: ".doc",
            MimeType: ["application/msword"],
        },
        {
            Group: "document",
            Extension: ".docx",
            MimeType: [
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ],
        },
        {
            Group: "document",
            Extension: ".xls",
            MimeType: ["application/vnd.ms-excel"],
        },
        {
            Group: "document",
            Extension: ".xlsx",
            MimeType: [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ],
        },
        {
            Group: "document",
            Extension: ".xml",
            MimeType: ["application/xml"],
        },
        {
            Group: "document",
            Extension: ".pdf",
            MimeType: ["application/pdf"],
        },
        {
            Group: "document",
            Extension: ".txt",
            MimeType: ["text/plain"],
        },
        {
            Group: "document",
            Extension: ".htm",
            MimeType: ["text/htm"],
        },
        {
            Group: "document",
            Extension: ".html",
            MimeType: ["text/html"],
        },
    ],
    KALEM_GRUP:{
        OFIS : 1,
        FATURA: 2,
        PERSONEL: 3,
        VERGI: 4,
        MUVEKKİL: 5,
        DOSYA: 6,
        DIGER: 7,
    }
};
export const Links = {
    Get(link, attr) {
        if (attr != undefined) {
            if (!Array.isArray(attr)) {
                attr = [attr];
            }
            for (var i = 0; i < attr.length; i++) {
                try {
                    link = link.replaceAll("{" + i + "}", attr[i].toString());
                } catch (e) {
                    console.error(e);
                }
            }
        }
        return link;
    },
    Login: '/login',
    Anasayfa: "/panel",
    Muvekkil: {
        Listele: "/muvekkil/listele",
        TalepListele: "/muvekkil/talep-listele",
        Islem: "/muvekkil/islem/{0}",
        Kart: "/muvekkil/kart/{0}",
    },
    Dosya: {
        Listele: "/dosya/listele",
        Arama: "/dosya/arama",
        Islem: "/dosya/islem/{0}",
        Kart: "/dosya/kart/{0}",
    },
    Durusma: {
        Listele: "/durusma/listele",
        Islem: "/durusma/islem/{0}",
    },
    Muhasebe: {
        Kart: "/muhasebe",
        Listele: "/muhasebe/listele",
        Islem: "/muhasebe/islem/{0}",
    },
    Gorev: {
        Listele: "/gorev/listele",
        Gorevlerim: "/gorev/gorevlerim",
        Islem: "/gorev/islem/{0}",
        Kart: "/gorev/kart/{0}",
    },
    Randevu: {
        Listele: "/randevu/listele",
        Takvim: "/randevu/takvim",
        Islem: "/randevu/islem/{0}",
    },
    Tebligat: {
        Listele: "/tebligat/listele",
        Islem: "/tebligat/islem/{0}",
    },
    Hakim: {
        Listele: "/hakim/listele",
        Islem: "/hakim/islem/{0}",
    },
    Bilirkisi: {
        Listele: "/bilirkisi/listele",
        Islem: "/bilirkisi/islem/{0}",
    },
    Arabulucu: {
        Listele: "/arabulucu/listele",
        Islem: "/arabulucu/islem/{0}",
    },
    Personel: {
        Listele: "/personel/listele",
        Islem: "/personel/islem/{0}",
        Kart: "/personel/kart/{0}",
        Profil: "/personel/profil",
        SifremiUnuttum: "/sifremi-unuttum",
    },
    Ayar: {
        Genel: "/genel-ayarlar",
    },
    Rol: {
        Listele: "/rol/listele",
        Islem: "/rol/islem/{0}",
    },
    Bildirim: {
        Listele: "/bildirim/listele",
        Kart: "/bildirim/kart/{0}",
    },
    MuvekkilPanel: {
        Anasayfa: "/muvekkil-panel/",
        Dosya: {
            Listele: "/muvekkil-panel/dosya/listele",
            Kart: "/muvekkil-panel/dosya/kart/{0}",
        },
        Durusma: {
            Listele: "/muvekkil-panel/durusma/listele",
            Kart: "/muvekkil-panel/durusma/kart/{0}",
        },
        Muhasebe: {
            Kart: "/muvekkil-panel/muhasebe",
            Listele: "/muvekkil-panel/muhasebe/listele",
            Islem: "/muvekkil-panel/muhasebe/kart/{0}",
        },
        Talep: {
            Listele: "/muvekkil-panel/talep/listele",
            Islem: "/muvekkil-panel/talep/islem/{0}",
        },
    }
};

export const Constant = {
    Dosya: {
        Turu: [
            { Id: 1, Adi: "Hukuk Davası" },
            { Id: 2, Adi: "İcra Davası" },
            { Id: 3, Adi: "Ceza Davası" },
            { Id: 4, Adi: "İdare Davası" },
            { Id: 5, Adi: "Danışmanlık" },
            { Id: 6, Adi: "Soruşturma" },
            { Id: 7, Adi: "İcra Takibi" },
        ],
        Durum: [
            { Id: 1, Adi: "Devam Ediyor" },
            { Id: 2, Adi: "Reddedildi" },
            { Id: 3, Adi: "Kabul Edildi" },
            { Id: 4, Adi: "Kısmen Kabul / Red Edildi" },
            { Id: 5, Adi: "İstinafta" },
            { Id: 6, Adi: "Yargıtay(Temyizde)" },
            { Id: 7, Adi: "Danıştay(Temyizde)" },
            { Id: 8, Adi: "Müracaata Bırakıldı" },
            { Id: 9, Adi: "İtiraz Edildi" },
            { Id: 10, Adi: "Kesinleşti" },
        ],
        Taraf: [
            { Id: 1, Adi: "Yediemin" },
            { Id: 2, Adi: "Veli" },
            { Id: 3, Adi: "Vasi Adayı" },
            { Id: 4, Adi: "Vasi" },
            { Id: 5, Adi: "Üçüncü Şahıs" },
            { Id: 6, Adi: "Uzman" },
            { Id: 7, Adi: "Temsilci" },
            { Id: 8, Adi: "Tasfiye Memuru" },
            { Id: 9, Adi: "Talepte Bulunan" },
            { Id: 10, Adi: "Talep Eden" },
            { Id: 11, Adi: "Şüpheli" },
            { Id: 12, Adi: "Şikayetçi" },
            { Id: 13, Adi: "Suçtan Zarar Gören" },
            { Id: 14, Adi: "Suça Sürüklenen Çocuk" },
            { Id: 15, Adi: "Sanık" },
            { Id: 16, Adi: "Rehin Sahibi" },
            { Id: 17, Adi: "Müşteki Şüpheli" },
            { Id: 18, Adi: "Müşteki Sanık" },
            { Id: 19, Adi: "Müşteki" },
            { Id: 20, Adi: "Müdahil" },
            { Id: 21, Adi: "Muris" },
            { Id: 22, Adi: "Mirasçı" },
            { Id: 23, Adi: "Malen Sorumlu" },
            { Id: 24, Adi: "Mağdur Sanık" },
            { Id: 25, Adi: "Mağdur" },
            { Id: 26, Adi: "Konkordato Komiseri" },
            { Id: 27, Adi: "Kiracı" },
            { Id: 28, Adi: "Kefil" },
            { Id: 29, Adi: "Kayyım" },
            { Id: 30, Adi: "Katılan Sanık" },
            { Id: 31, Adi: "Katılan" },
            { Id: 32, Adi: "Karşı Taraf (Davacı)" },
            { Id: 33, Adi: "Karşı Taraf" },
            { Id: 34, Adi: "İtiraz Eden (Davalı)" },
            { Id: 35, Adi: "İtiraz Eden (Davacı)" },
            { Id: 36, Adi: "İtiraz Eden" },
            { Id: 37, Adi: "İpotek Sahibi" },
            { Id: 38, Adi: "İhbar Olunan" },
            { Id: 39, Adi: "İhbar Edilen" },
            { Id: 40, Adi: "İhbar Eden" },
            { Id: 41, Adi: "İflas İdare Memuru" },
            { Id: 42, Adi: "Hissedar" },
            { Id: 43, Adi: "Haciz Sahibi" },
            { Id: 44, Adi: "Feri Müdahil" },
            { Id: 45, Adi: "Davalı-Karşı Davacı" },
            { Id: 46, Adi: "Davalı" },
            { Id: 47, Adi: "Davacı-Karşı Davalı" },
            { Id: 48, Adi: "Davacı" },
            { Id: 49, Adi: "Dahili Davacı" },
            { Id: 50, Adi: "Borçlu" },
            { Id: 51, Adi: "Bilinmiyor" },
            { Id: 52, Adi: "Asli Müdahil" },
            { Id: 53, Adi: "Alacaklı" },
        ]
    },
    Durusma: {
        Turu: [
            { Id: 1, Adi: "Celse" },
            { Id: 2, Adi: "Keşif" },
            { Id: 3, Adi: "Müzekkere" },
            { Id: 4, Adi: "İnceleme" },
        ],
        Durum: [
            { Id: 1, Adi: "Günü Verildi" },
            { Id: 2, Adi: "Duruşma Yapıldı" },
            { Id: 3, Adi: "Görev Listesi Çıkarıldı" },
            { Id: 4, Adi: "Ertelendi" },
            { Id: 5, Adi: "Keşif Ertelendi" },
            { Id: 6, Adi: "İptal Edildi" },
            { Id: 7, Adi: "Duruşma Devam Ediyor" },
            { Id: 8, Adi: "Duruşma Yapılmadı" },
            { Id: 9, Adi: "Keşif Yapıldı" },
            { Id: 10, Adi: "Keşif Yapılmadı" },
            { Id: 11, Adi: "Müzekkere Devam Ediyor" },
            { Id: 12, Adi: "Müzekkere Yapılmadı" },
            { Id: 13, Adi: "Duruşma Evrakı Hazırlandı" }
        ]
    },
    Muvekkil: {
        Turu: [
            { Id: 1, Adi: "Şahıs" },
            { Id: 2, Adi: "Şirket" },
            { Id: 3, Adi: "Kamu" },
        ],
    },
    MuvekkilTalep: {
        Turu: [
            { Id: 1, Adi: "Randevu Talebi" },
            { Id: 2, Adi: "Dosya Bilgi İşlemleri" },
            { Id: 3, Adi: "Muhasebe İşlemleri" },
            { Id: 4, Adi: "Dosya Evrak İşlemleri" },
            { Id: 5, Adi: "Diğer İşlemler" },
        ],
        Durum: [
            { Id: 1, Adi: "Beklemede" },
            { Id: 2, Adi: "İnceleniyor" },
            { Id: 3, Adi: "Tamamlandı" },
            { Id: 4, Adi: "İptal Edildi" },
        ],
    },
    Randevu: {
        Turu: [
            { Id: 1, Adi: "Randevu" },
            { Id: 2, Adi: "Duruşma" },
            { Id: 3, Adi: "Diğer" },
        ],
        Durum: [
            { Id: 1, Adi: "Beklemede" },
            { Id: 2, Adi: "Tamamlandı" },
            { Id: 3, Adi: "İptal Edildi" },
        ],
    },
    Gorev: {
        Oncelik: [
            { Id: 1, Adi: "Normal" },
            { Id: 2, Adi: "Acil" },
            { Id: 3, Adi: "Öncelikli" },
            { Id: 4, Adi: "Ertelenebilir" },
        ],
        Durum: [
            { Id: 1, Adi: "Beklemede" },
            { Id: 2, Adi: "Kısmen Tamamlandı" },
            { Id: 3, Adi: "Tamamlandı" },
            { Id: 4, Adi: "İptal Edildi" },
        ],
    },
    PersonelGorev: {
        Durum: [
            { Id: 1, Adi: "Beklemede" },
            { Id: 2, Adi: "Yapıldı" },
            { Id: 3, Adi: "Yapılmadı" },
        ],
        Onay: [
            { Id: 0, Adi: "-" },
            { Id: 1, Adi: "Onaylandı" },
            { Id: 2, Adi: "Onaylanmadı" },
        ],
        Puan: [
            { Id: 1, Adi: "😠" },
            { Id: 2, Adi: "🙁" },
            { Id: 3, Adi: "😑" },
            { Id: 4, Adi: "😊" },
            { Id: 5, Adi: "😄" },
        ],
    },
    Muhasebe: {
        Yon: [
            { Id: 1, Adi: "Gelir" },
            { Id: 0, Adi: "Gider" },
        ],
        Kdv: [
            { Id: 0, Adi: "Kdvsiz" },
            { Id: 1, Adi: "% 1" },
            { Id: 8, Adi: "% 8" },
            { Id: 18, Adi: "% 18" },
        ],
        KalemGrup: [
            { Id: 1, Adi: "Ofis Masrafları" },
            { Id: 2, Adi: "Fatura" },
            { Id: 3, Adi: "Personel" },
            { Id: 4, Adi: "Vergi" },
            { Id: 5, Adi: "Müvekkil" },
            { Id: 6, Adi: "Dosya" },
            { Id: 7, Adi: "Diğer" },
        ]
    },
    Evrak: {
        Turu: [
            { Id: 1, Adi: "Merci" },
            { Id: 2, Adi: "Müvekkil" },
            { Id: 3, Adi: "Karşı Taraf" },
        ],
    },
    Bildirim: {
        Turu: [
            { Id: 1, Adi: "Genel" },
            { Id: 2, Adi: "Dosya" },
            { Id: 3, Adi: "Duruşma" },
            { Id: 4, Adi: "Müvekkil" },
            { Id: 5, Adi: "Görev" },
            { Id: 6, Adi: "Muhasebe" },
            { Id: 7, Adi: "Randevu" },
            { Id: 8, Adi: "Personel" },
        ]
    },
    Tebligat: {
        Durum: [
            { Id: 1, Adi: "Beklemede" },
            { Id: 2, Adi: "İncelendi" },
        ],
    },
};

