<template>
    <div class="sidebar sidebar-left offcanvas offcanvas-start" id="leftSidebar">
        <div class="sidebar-content offcanvas-body">
            <!--<a class="btn closebtn" data-bs-dismiss="offcanvas" aria-label="Close"><i class="fa fa-times"></i></a>-->
            <!-- SIDEBAR LOGO START -->
            <div class="sidebar-logo">
                <ul class="site-logo">
                    <li>
                        <div class="logo">
                            <router-link :to="Links.Get(Links.Anasayfa)" :title="appData.name" class="d-block">
                                <img src="/assets/img/logo.png" :alt="appData.name" />
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- SIDEBAR LOGO END -->
            <nav class="main-menu">
                <perfect-scrollbar class="scrollbar-sidebar" id="collapseMenu">
                    <ul class="nav metimenu">
                        <!--<li class="sidebar-header"><span>Navigator</span></li>-->
                        <li v-for="(r, index) in menu" :key="r">
                            <template v-if="r.child">
                                <a data-bs-toggle="collapse" :href="'#collapse_menu_' + index" role="button" class="collapsed" aria-expanded="false" :aria-controls="'collapse_menu_' + index">
                                    <i :class="r.class"></i><span>{{ r.text }}</span>
                                    <i class="chevron-down-solid"></i>
                                    <i class="chevron-right-solid"></i>
                                </a>
                                <div data-bs-parent="#collapseMenu" class="collapse" :id="'collapse_menu_' + index">
                                    <ul class="sub-menu">
                                        <li v-for="c in r.child" :key="c">
                                            <router-link :to="c.link"><i :class="c.class"></i><span>{{ c.text }}</span></router-link>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template v-else>
                                <router-link :to="r.link"><i :class="r.class"></i><span>{{ r.text }}</span></router-link>
                            </template>
                        </li>
                        <li>
                            <a href="javascript:void(0)" @click="logout"><i class="exit"></i><span>Çıkış</span></a>
                        </li>
                    </ul>
                </perfect-scrollbar>
            </nav>
        </div>
    </div>
</template>
<script>
    import { reactive, toRefs } from "vue";
    import { Offcanvas } from "bootstrap";

    import { Links } from "@/helpers/enums";
    import { appData } from "@/helpers/global";
    import { Web } from "@/helpers/web";

    import { PerfectScrollbar } from "vue3-perfect-scrollbar";

    export default {
        components: {
            PerfectScrollbar,
        },
        setup() {
            const e = reactive({
                menu: [
                    { link: Links.Get(Links.Anasayfa), class: "home", text: "Anasayfa" },
                    {
                        class: "client-management", text: "Müvekkil Yönetimi",
                        child: [
                            { link: Links.Get(Links.Muvekkil.Islem, 0), class: "client-management", text: "Yeni Müvekkil", },
                            { link: Links.Get(Links.Muvekkil.Listele), class: "client-management", text: "Müvekkil Listele", },
                            { link: Links.Get(Links.Muvekkil.TalepListele), class: "calendar", text: "Müvekkil Talepleri" },
                        ],
                    },
                    {
                        class: "file-management", text: "Dava Dosya Yönetimi",
                        child: [
                            { link: Links.Get(Links.Dosya.Islem, 0), class: "file-management", text: "Yeni Dosya", },
                            { link: Links.Get(Links.Dosya.Listele), class: "file-management", text: "Dosya Listele", },
                        ]
                    },
                    { link: Links.Get(Links.Durusma.Listele), class: "trial-management", text: "Duruşma Yönetimi", },
                    {
                        class: "assignment", text: "Görevlendirme",
                        child: [
                            { link: Links.Get(Links.Gorev.Islem, 0), class: "add-assignment", text: "Yeni Görev", },
                            { link: Links.Get(Links.Gorev.Listele), class: "list-tasks", text: "Görev Listele", },
                            { link: Links.Get(Links.Gorev.Gorevlerim), class: "list-tasks", text: "Görevlerim", },
                        ]
                    },
                    {
                        class: "accounting", text: "Muhasebe",
                        child: [
                            { link: Links.Get(Links.Muhasebe.Kart), class: "currency-unit", text: "Gelir Gider Takibi", },
                            { link: Links.Get(Links.Muhasebe.Listele), class: "accounting", text: "Muhasebe Raporları", },
                        ],
                    },
                    { link: Links.Get(Links.Randevu.Takvim), class: "calendar", text: "Randevu" },
                    { link: Links.Get(Links.Tebligat.Listele), class: "personnel", text: "Tebligat" },
                    { link: Links.Get(Links.Personel.Listele), class: "personnel", text: "Ekip" },
                    { link: Links.Get(Links.Hakim.Listele), class: "personnel", text: "Hakim" },
                    { link: Links.Get(Links.Arabulucu.Listele), class: "personnel", text: "Arabulucu" },
                    { link: Links.Get(Links.Bilirkisi.Listele), class: "personnel", text: "Bilirkişi" },
                    {
                        class: "settings", text: "Ayarlar",
                        child: [
                            { link: Links.Get(Links.Ayar.Genel), class: "settings", text: "Genel Ayarlar", },
                            { link: Links.Get(Links.Personel.Profil), class: "personnel", text: "Profil", },
                            { link: Links.Get(Links.Rol.Listele), class: "roller", text: "Roller", },
                            { link: Links.Get(Links.Bildirim.Listele), class: "notification", text: "Bildirimler" }
                        ],
                    },
                ],
                logout() {
                    appData.user.logout();
                    Web.Redirect(appData.user.loginPage);
                },
            });
            return { ...toRefs(e) };
        },
        watch: {
            $route(to, from) {
                var left = document.getElementById('leftSidebar');
                if (left) {
                    var offCanvas = Offcanvas.getInstance(left);
                    if (offCanvas) {
                        offCanvas.hide();
                    }
                }
            }
        }
    };
</script>
