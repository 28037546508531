import { createRouter, createWebHistory } from "vue-router";

import { Enums } from "../helpers/enums";
import { appData } from "../helpers/global";
//import { Wait } from "../helpers/web";

import DefaultView from "../views/DefaultView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import ErrorView from "../views/ErrorView.vue";

const routes = [
    {
        path: "/",
        name: "default",
        component: DefaultView, // Ana komponentler doğrudan yüklenmelidir
        props: true,
        meta: {
            title: "Smart Law",
            theme: Enums.THEME.EMPTY,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
        props: true,
        meta: {
            title: "Giriş",
            theme: Enums.THEME.EMPTY,
        },
    },
    {
        path: "/muvekkil-panel/giris",
        name: "muvekkil-panel-giris",
        component: () => import("../views/muvekkil-panel/LoginView.vue"),
        props: true,
        meta: {
            title: "Giriş",
            theme: Enums.THEME.EMPTY,
        },
    },
    {
        path: "/giris", //path: '/login',
        name: "giris",
        component: () => import("../views/personel/LoginView.vue"),
        props: true,
        meta: {
            title: "Giriş",
            theme: Enums.THEME.EMPTY,
        },
    },
    {
        path: "/sifremi-unuttum", //path: '/forgot-password',
        name: "sifremi-unuttum",
        component: () => import("../views/personel/ForgotPasswordView.vue"),
        props: true,
        meta: {
            title: "Şifremi Unuttum",
            theme: Enums.THEME.EMPTY,
        },
    },
    {
        path: "/sifre-yenile", //path: '/reset-password',
        name: "sifre-yenile",
        component: () => import("../views/personel/ResetPasswordView.vue"),
        props: true,
        meta: {
            title: "Şifre Yenile",
            theme: Enums.THEME.EMPTY,
        },
    },
    // ANASAYFA
    {
        path: "/panel",
        name: "dashboard",
        component: () => import("../views/DashboardView.vue"),
        props: true,
        meta: {
            title: "Anasayfa",
            theme: Enums.THEME.CLASSIC,
        },
    },    
    // MÜVEKKİL
    {
        path: "/muvekkil/listele",
        name: "muvekkil-listele",
        component: () => import("../views/muvekkil/ListView.vue"),
        meta: {
            title: "Müvekkil Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/muvekkil/talep-listele",
        name: "muvekkil-talep-listele",
        component: () => import("../views/muvekkil/TalepListView.vue"),
        meta: {
            title: "Müvekkil Talep Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/muvekkil/islem/:id",
        name: "muvekkil-islem",
        component: () => import("../views/muvekkil/EditView.vue"),
        props: true,
        meta: {
            title: "Müvekkil İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/muvekkil/kart/:id",
        name: "muvekkil-kart",
        component: () => import("../views/muvekkil/CardView.vue"),
        props: true,
        meta: {
            title: "Müvekkil Kartı",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // DOSYA
    {
        path: "/dosya/listele",
        name: "dosya-listele",
        component: () => import("../views/dosya/ListView.vue"),
        props: true,
        meta: {
            title: "Dosya Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/dosya/arama",
        name: "dosya-arama",
        component: () => import("../views/dosya/SearchListView.vue"),
        props: true,
        meta: {
            title: "Dosya Arama",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/dosya/islem/:id",
        name: "dosya-islem",
        component: () => import("../views/dosya/EditView.vue"),
        props: true,
        meta: {
            title: "Dosya İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/dosya/kart/:id",
        name: "dosya-kart",
        component: () => import("../views/dosya/CardView.vue"),
        props: true,
        meta: {
            title: "Dosya Kartı",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // DURUŞMA
    {
        path: "/durusma/listele",
        name: "durusma-listele",
        component: () => import("../views/durusma/ListView.vue"),
        props: true,
        meta: {
            title: "Duruşma Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/durusma/islem/:id",
        name: "durusma-islem",
        component: () => import("../views/durusma/EditView.vue"),
        props: true,
        meta: {
            title: "Duruşma İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // GÖREV
    {
        path: "/gorev/listele",
        name: "gorev-listele",
        component: () => import("../views/gorev/ListView.vue"),
        props: true,
        meta: {
            title: "Görev Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/gorev/gorevlerim",
        name: "gorev-gorevlerim",
        component: () => import("../views/gorev/MyListView.vue"),
        props: true,
        meta: {
            title: "Görevlerim",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/gorev/islem/:id",
        name: "gorev-islem",
        component: () => import("../views/gorev/EditView.vue"),
        props: true,
        meta: {
            title: "Görev Atama",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/gorev/kart/:id",
        name: "gorev-kart",
        component: () => import("../views/gorev/CardView.vue"),
        props: true,
        meta: {
            title: "Görevi İncele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // MUHASEBE
    {
        path: "/muhasebe",
        name: "muhasebe",
        component: () => import("../views/muhasebe/CardView.vue"),
        props: true,
        meta: {
            title: "Gelir Gider Takibi",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/muhasebe/listele",
        name: "muhasebe-listele",
        component: () => import("../views/muhasebe/ListView.vue"),
        props: true,
        meta: {
            title: "Gelir Gider Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/muhasebe/islem/:id",
        name: "muhasebe-islem",
        component: () => import("../views/muhasebe/EditView.vue"),
        props: true,
        meta: {
            title: "Gelir Gider İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },       
    // RANDEVU
    {
        path: "/randevu/listele",
        name: "randevu-listele",
        component: () => import("../views/randevu/ListView.vue"),
        props: true,
        meta: {
            title: "Randevu Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/randevu/takvim",
        name: "randevu-takvim",
        component: () => import("../views/randevu/CalendarView.vue"),
        props: true,
        meta: {
            title: "Randevu Takvimi",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/randevu/islem/:id",
        name: "randevu-islem",
        component: () => import("../views/randevu/EditView.vue"),
        props: true,
        meta: {
            title: "Randevu İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // TEBLİGAT
    {
        path: "/tebligat/listele",
        name: "tebligat-listele",
        component: () => import("../views/tebligat/ListView.vue"),
        props: true,
        meta: {
            title: "Tebligat Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/tebligat/islem/:id",
        name: "tebligat-islem",
        component: () => import("../views/tebligat/EditView.vue"),
        props: true,
        meta: {
            title: "Tebligat İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // HAKİM
    {
        path: "/hakim/listele",
        name: "hakim-listele",
        component: () => import("../views/hakim/ListView.vue"),
        props: true,
        meta: {
            title: "Hakim Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/hakim/islem/:id",
        name: "hakim-islem",
        component: () => import("../views/hakim/EditView.vue"),
        props: true,
        meta: {
            title: "Hakim İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // BİLİRKİŞİ
    {
        path: "/bilirkisi/listele",
        name: "bilirkisi-listele",
        component: () => import("../views/bilirkisi/ListView.vue"),
        props: true,
        meta: {
            title: "Bilirkişi Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/bilirkisi/islem/:id",
        name: "bilirkisi-islem",
        component: () => import("../views/bilirkisi/EditView.vue"),
        props: true,
        meta: {
            title: "Bilirkişi İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // ARABULUCU
    {
        path: "/arabulucu/listele",
        name: "arabulucu-listele",
        component: () => import("../views/arabulucu/ListView.vue"),
        props: true,
        meta: {
            title: "Arabulucu Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/arabulucu/islem/:id",
        name: "arabulucu-islem",
        component: () => import("../views/arabulucu/EditView.vue"),
        props: true,
        meta: {
            title: "Arabulucu İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // PERSONEL
    {
        path: "/personel/listele",
        name: "personel-listele",
        component: () => import("../views/personel/ListView.vue"),
        props: true,
        meta: {
            title: "Personel Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/personel/islem/:id",
        name: "personel-islem",
        component: () => import("../views/personel/EditView.vue"),
        props: true,
        meta: {
            title: "Personel İşlem",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/personel/kart/:id",
        name: "personel-kart",
        component: () => import("../views/personel/CardView.vue"),
        props: true,
        meta: {
            title: "Personel Kartı",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/personel/profil",
        name: "personel-profil",
        component: () => import("../views/personel/ProfileView.vue"),
        props: true,
        meta: {
            title: "Profilim",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // AYAR
    {
        path: "/genel-ayarlar",
        name: "genel-ayarlar",
        component: () => import("../views/ayar/GeneralView.vue"),
        props: true,
        meta: {
            title: "Genel Ayarlar",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // ROL
    {
        path: "/rol/listele",
        name: "rol-listele",
        component: () => import("../views/rol/ListView.vue"),
        props: true,
        meta: {
            title: "Rol Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/rol/islem/:id",
        name: "rol-islem",
        component: () => import("../views/rol/EditView.vue"),
        props: true,
        meta: {
            title: "Rol Ekle",
            theme: Enums.THEME.CLASSIC,
        },
    },
    // BİLDİRİM
    {
        path: "/bildirim/listele",
        name: "bildirim-listele",
        component: () => import("../views/bildirim/ListView.vue"),
        props: true,
        meta: {
            title: "Bildirim Listele",
            theme: Enums.THEME.CLASSIC,
        },
    },
    {
        path: "/bildirim/kart/:id",
        name: "bildirim-kart",
        component: () => import("../views/bildirim/CardView.vue"),
        props: true,
        meta: {
            title: "Bildirim Kartı",
            theme: Enums.THEME.CLASSIC,
        },
    },
    //MÜVEKKİL - PANEL
    {
        path: "/muvekkil-panel/",
        name: "muvekkil-panel-dashboard",
        component: () => import("../views/muvekkil-panel/DashboardView.vue"),
        props: true,
        meta: {
            title: "Anasayfa",
            theme: Enums.THEME.CUSTOMER,
        },
    },
    {
        path: "/muvekkil-panel/dosya/listele",
        name: "muvekkil-panel-dosya-listele",
        component: () => import("../views/muvekkil-panel/dosya/ListView.vue"),
        props: true,
        meta: {
            title: "Dosya Listele",
            theme: Enums.THEME.CUSTOMER,
        },
    },
    {
        path: "/muvekkil-panel/dosya/kart/:id",
        name: "muvekkil-panel-dosya-kart",
        component: () => import("../views/muvekkil-panel/dosya/CardView.vue"),
        props: true,
        meta: {
            title: "Dosya Kartı",
            theme: Enums.THEME.CUSTOMER,
        },
    },
    {
        path: "/muvekkil-panel/durusma/listele",
        name: "muvekkil-panel-durusma-listele",
        component: () => import("../views/muvekkil-panel/durusma/ListView.vue"),
        props: true,
        meta: {
            title: "Duruşma Listele",
            theme: Enums.THEME.CUSTOMER,
        },
    },
    {
        path: "/muvekkil-panel/muhasebe/listele",
        name: "muvekkil-panel-muhasebe-listele",
        component: () => import("../views/muvekkil-panel/muhasebe/ListView.vue"),
        props: true,
        meta: {
            title: "Gelir Gider Listele",
            theme: Enums.THEME.CUSTOMER,
        },
    },
    {
        path: "/muvekkil-panel/bildirim/listele",
        name: "muvekkil-panel-bildirim-listele",
        component: () => import("../views/muvekkil-panel/bildirim/ListView.vue"),
        props: true,
        meta: {
            title: "Bildirim Listele",
            theme: Enums.THEME.CUSTOMER,
        },
    },
    {
        path: "/muvekkil-panel/bildirim/kart/:id",
        name: "muvekkil-panel-bildirim-kart",
        component: () => import("../views/muvekkil-panel/bildirim/CardView.vue"),
        props: true,
        meta: {
            title: "Bildirim Kartı",
            theme: Enums.THEME.CUSTOMER,
        },
    },
    // 500
    {
        path: "/error",
        name: "error",
        component: ErrorView,
        props: true,
        meta: {
            title: "Bir Hata Oldu!",
            theme: Enums.THEME.EMPTY,
        },
    },
    // 404
    {
        path: "/:pathMatch(.*)*", //path: "/:catchAll(.*)",
        name: "not-found",
        component: NotFoundView,
        props: true,
        meta: {
            title: "Sayfa Bulunamadı!",
            theme: Enums.THEME.EMPTY,
        },
    },
];

//console.log(process.env.BASE_URL);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//router.beforeResolve((to, from, next) => {
//  if (to.name) {
//    Wait.Page(true);
//  }
//  next();
//});

//router.beforeEach((to, from) => {});
router.afterEach((to) => {
  document.title = to.meta.title;
  appData.page = {}; // temp data kullanimi
  // Complete the animation of the route progress bar.
  //Wait.Page(false);
});

export default router;
