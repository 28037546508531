<template>
  <!--<header class="header">
        <h1>Header Alanı</h1>
        <img width="42" class="rounded-circle" :src="C.Str(appData.user.by.Profil, appData.default.profile)" :alt="C.Str(appData.user.by.Adi) + ' ' + C.Str(appData.user.by.Soyadi)">
        <div class="title">{{C.Str(appData.user.by.Adi)}} {{C.Str(appData.user.by.Soyadi)}}</div>
        <div class="sub-title" v-if="appData.user.by.RolId"> {{C.Str(appData.user.by.RolId.Adi)}} </div>
        <button class="btn btn-primary" @click="logout">Çıkış Yap</button>
    </header>-->
  <header>
    <div class="row search-wrapper" :style="{ display: search.state ? 'flex' : 'none' }">
      <div class="col">
        <div class="search">
          <i class="fa fa-search"></i>
          <input type="text" placeholder="Aramak istediğiniz kelimeyi giriniz..." v-model="search.text" @keyup.enter="search.find"/>
        </div>
      </div>
      <div class="col button-close" style="max-width:100px">
        <button class="btn btn-primary" @click="search.state = false"><i class="fa fa-times"/></button>
      </div>
    </div>
    <div class="row header-nav" :style="{ display: search.state ? 'none' : 'flex' }">
      <div class="col">
        <a class="btn-left-sidebar-open" data-bs-toggle="offcanvas" href="#leftSidebar" role="button" aria-controls="leftSidebar"><i class="fa fa-bars"></i></a>
        <div class="header-logo">
          <ul class="site-logo">
            <li>
              <!-- LOGO START -->
              <div class="logo">
                <router-link :to="Links.Get(Links.Anasayfa)" :title="appData.name" class="d-block">
                  <img src="/assets/img/logo.png" :alt="appData.name" />
                </router-link>
              </div>
              <!-- LOGO END -->
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary" @click="search.state = true">
          <i class="fa fa-search"></i>
        </button>
        <div class="search desktop">
          <i class="fa fa-search"></i>
          <input type="text" placeholder="Lütfen aramak istediğiniz kelimeyi giriniz..." v-model="search.text" @keyup.enter="search.find"/>
        </div>
      </div>
      <div class="col">
        <div class="navbar-right">
          <ul>
            <li class="notification-dropdown dropdown">
              <!-- <a class="dropdown-toggle" data-toggle="dropdown" href="#" title="Bildirimler" aria-expanded="true">  -->
              <!-- <a href="#" title="Bildirimler"> -->
              <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" @click="notification.saveRead">
                <i class="notification"></i>
                <span class="count" v-if="notification.isNew"></span>
              </button>
              <!-- <i class="bildirim"></i>                                
            </a> -->
              <div class="dropdown-menu">
                <template v-if="notification.count > 0">
                    <perfect-scrollbar style="max-height:25rem;">
                        <router-link :to="Links.Get(Links.Bildirim.Kart, r.Id)" class="dropdown-item" v-for="r in notification.data" :key="r.Id" :class="[ r.Okundu ? 'read' : 'unread' ]">
                            <div class="dropdown-text">
                                <p>{{ r.Konu }}</p>
                                <span class="notification-date">{{D.DateTimeToString(r.Tarih)}}</span>
                            </div>
                        </router-link>
                    </perfect-scrollbar>
                   <router-link :to="Links.Get(Links.Bildirim.Listele)" class="dropdown-item">
                    <div class="dropdown-text">
                      <span class="all-notification">Tümünü Gör</span>
                    </div>
                   </router-link>
                </template>
                <template v-else>
                  <div class="dropdown-item">
                    <div class="dropdown-text">
                      <span class="not-found">Bildirim Bulunamadı</span>
                    </div>
                  </div>
                </template>
              </div>
            </li>
            <!-- <li class="user-area"> -->
            <!-- <a class="dropdown-toggle" data-toggle="dropdown" href="#" title="Kullanıcı"> -->
            <!-- <a href="#" title="Kullanıcı">
                <div class="user-box"></div>
            </a>
        </li> -->
            <li class="btn-group">
              <div>
                <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown">
                  <div class="user-box">
                    <img :src="Web.Server(appData.user.by.Profil, Web.Url(appData.default.profile))" :alt="C.Str(appData.user.by.Adi) + ' ' + C.Str(appData.user.by.Soyadi)"/>
                  </div>
                  <span>{{ C.Str(appData.user.by.Adi) }} {{ C.Str(appData.user.by.Soyadi) }}</span>
                </button>
                <div class="dropdown-menu">
                  <router-link :to="Links.Get(Links.Personel.Profil)" class="dropdown-item"><i class="profile"></i>Profil</router-link>
                  <router-link :to="Links.Get(Links.Muhasebe.Listele)" class="dropdown-item"><i class="accounting"></i>Muhasebe</router-link>
                  <router-link :to="Links.Get(Links.Randevu.Listele)" class="dropdown-item"><i class="calendar"></i>Randevu</router-link>
                  <router-link :to="Links.Get(Links.Ayar.Genel)" class="dropdown-item"><i class="settings"></i>Ayarlar</router-link>
                  <div class="dropdown-divider"></div>
                  <a href="javascript:void(0)" @click="logout" class="dropdown-item"><i class="exit"></i>Çıkış</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
    import { reactive, toRefs, computed } from "vue";
    import { useRouter } from "vue-router";

    import { Links } from "@/helpers/enums";
    import { appData, C, J } from "@/helpers/global";
    import { Ajax, MethodType, Web } from "@/helpers/web";

    import { PerfectScrollbar } from "vue3-perfect-scrollbar";

    export default {
        components: {
            PerfectScrollbar,
        },
  setup() {
    const router = useRouter();
    const e = reactive({
      search: {
        state: false,
        text: "",
        find() {
          if (e.search.text.trim() != "") {
              router.push(Links.Get(Links.Dosya.Arama) + '?search=' + e.search.text.trim());
             e.search.text = '';
          }
        },
      },
      logout() {
        appData.user.logout();
        Web.Redirect(appData.user.loginPage);
      },
      notification: {
        firstId : 0,
        endId: 0,
        data: [],
        init() {
            var s = J.Parse(sessionStorage.getItem('notification'), "{}");
              if (s.list) {
                e.notification.data = J.Clone(s.list);
                e.notification.firstId = s.firstId;
                e.notification.endId = s.endId;
                if (e.notification.data.length > 0) {
                    e.notification.getNew();
                }
                else { e.notification.get(0); }
            }
            else { e.notification.get(0); }
        },
        saveSession() {
            var s = {
                firstId: e.notification.firstId,
                endId: e.notification.endId,
                list: e.notification.data,
            };
            sessionStorage.setItem("notification", J.String(s, "{}"));
            setTimeout(function () { e.notification.getNew() }, 10000);
        },
        get(endId) {
              Ajax.Api(MethodType.Get, 'bildirim/first/' + endId, null).then((data) => {
                var add = function(item) {
                    for (var j = 0; j < e.notification.data.length; j++) {
                        if (e.notification.data[j].Id == item.Id) {
                            return;
                        }
                    }
                    e.notification.data.push(J.Clone(item));
                };
                for (var i = 0; i < data.List.length; i++) {
                    add(data.List[i]);
                }
                for (var i = 0; i < e.notification.data.length; i++) {
                    var r = e.notification.data[i];
                    if (r.Id > 0) {
                        if (e.notification.firstId == 0) { e.notification.firstId = r.Id; }
                        if (e.notification.endId == 0) { e.notification.endId = r.Id; }

                        if (r.Id > e.notification.firstId) {
                            e.notification.firstId = r.Id;
                        }
                        if (r.Id < e.notification.endId) {
                            e.notification.endId = r.Id;
                        }
                    }
                }
                e.notification.saveSession();
            });
        },
        getNew() {
              Ajax.Api(MethodType.Get, 'bildirim/new/' + e.notification.firstId, null).then((data) => {
                var add = function (item) {
                    for (var j = 0; j < e.notification.data.length; j++) {
                        if (e.notification.data[j].Id == item.Id) {
                            return;
                        }
                    }
                    e.notification.data.push(J.Clone(item));
                };
                for (var i = 0; i < data.List.length; i++) {
                    add(data.List[i]);
                }
                for (var i = 0; i < e.notification.data.length; i++) {
                    var r = e.notification.data[i];
                    if (r.Id > 0) {
                        if (e.notification.firstId == 0) { e.notification.firstId = r.Id; }
                        if (e.notification.endId == 0) { e.notification.endId = r.Id; }

                        if (r.Id > e.notification.firstId) {
                            e.notification.firstId = r.Id;
                        }
                        if (r.Id < e.notification.endId) {
                            e.notification.endId = r.Id;
                        }
                    }
                }
                e.notification.saveSession();
            }); 
        },
        saveRead() {
            var status = false;
            for (var i = 0; i < e.notification.data.length; i++) {
                if (!e.notification.data[i].Okundu) {
                    status = true;
                    break;
                }
            }
            if (status) {
                Ajax.Api(MethodType.Put, "bildirim/readed", { FirstId: e.notification.firstId }, null).then((data) => {
                    for (var i = 0; i < e.notification.data.length; i++) {
                        e.notification.data[i].Okundu = true;
                    }
                    e.notification.saveSession();
                });
            }
        },
        count: computed(() => {
          return e.notification.data.length;
        }),
        isNew: computed(() => {
          for (var i = 0; i < e.notification.data.length; i++) {
            if (!e.notification.data[i].Okundu) {
              return true;
            }
          }
          return false;
        }),
        newCount: computed(() => {
          var counter = 0;
          for (var i = 0; i < e.notification.data.length; i++) {
            if (!e.notification.data[i].Okundu) {
              counter++;
            }
          }
          return counter;
        }),
      },
    });
    //var bildirim = {
    //    _bus: appData.bus.init(),
    //    bildirimList: [],
    //    bildirimCount : 0,
    //};
    //appData.bus.on(bildirim, 'bildirimList,bildirimCount', function (data) {
    //    //for (var i = 0; i < data.length; i++) {
    //    //    data[i].key
    //    //    data[i].value
    //    //}
    //}, true);
    //appData.bus.get(bildirim, 'bildirimCount', function (value) {
    //    bildirim.bildirimCount = value;
    //});
    //appData.bus.emit(bildirim, 'bildirimCount', 5, true);
      return { ...toRefs(e) };
    },
    mounted() {
        this.notification.init();
    }
};
</script>
