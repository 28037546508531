/* eslint-disable no-useless-escape */

import axios from "axios";
import SweetAlert2 from "sweetalert2";
import { Enums, Links } from "./enums";
import { appData, C, Lang, Text, J, Rnd } from "./global";
// SupportType, MethodType, AjaxPromise, Web, Msg, Popup, Wait, Ajax, Exporter

// Ajax Method
export const SupportType = {
  Text: 1,
  Video: 2,
  Carousel: 3,
};

// Ajax Method
export const MethodType = {
  Get: "get",
  Post: "post",
  Put: "put",
  Delete: "delete",
  Filter: "patch",
};

// Ajax Promise
export var AjaxPromise = function (callback) {
  var ok = function () {},
    err = function () {},
    end = function () {};

  var x = this;
  x.isAlert = true;
  x.then = function (fnc) {
    ok = fnc;
    return x;
  };
  x.catch = function (fnc) {
    x.isAlert = false;
    err = fnc;
    return x;
  };
  x.finally = function (fnc) {
    end = fnc;
    return x;
  };

  setTimeout(function () {
    callback(ok, err, end, x.isAlert);
  }, 1);
  return x;
};

export class Web {
  constructor() {}
  static IsMobile() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }
  static IsMobileTablet() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }
  static ConnectDetect(isMessage) {
    if (!navigator.onLine) {
      if (C.Bool(isMessage, true)) {
        Msg.Warning("İnternet bağlantısı yok!");
      }
      return false;
    }
    return true;
  }
  static waitMethodList = [];
  static Form(callback, e) {
    var sender;
    if (e) {
      sender = e.currentTarget ?? e.target;
    }
    if (!Wait.IsButton(sender)) {
      if (Web.ConnectDetect(true)) {
        if (sender) {
          Wait.Button(sender, true);
          Wait.Block(sender.closest('[data-form="true"]'), true);
          var wait = function () {
            Wait.Button(sender, false);
            Wait.Block(sender.closest('[data-form="true"]'), false);
          };
          callback(wait);
        } else {
          callback(function () {});
        }
      }
    }
  }
  static Modal(callback, e) {
    var sender = e.currentTarget ?? e.target;
    if (!Wait.IsButton(sender)) {
      if (Web.ConnectDetect(true)) {
        Wait.Button(sender, true);
        Wait.Block(sender.closest(".modal-container"), true);

        var wait = function () {
          Wait.Button(sender, false);
          Wait.Block(sender.closest(".modal-container"), false);
        };
        callback(wait);
      }
    }
  }
  static List(callback, dataId) {
    var sender;
    if (C.Str(dataId) == "" && sender == null) {
        sender = document.querySelector(".smart-table,.smart-list"); // Sender verilmedi ise ilk gelen tablo 
    } else {
      var l = document.querySelector('[id="' + dataId + '"]');
      if (l) {
          sender = l.closest(".smart-table,.smart-list");
      }
    }
    if (sender) {
      if (!Wait.IsBlock(sender)) {
        if (Web.ConnectDetect(true)) {
          Wait.Block(sender, true);
          callback(function () {
            Wait.Block(sender, false);
          });
        }
      }
    }
  }
  static Url(link) {
    return Web.GetFullUrl(link);
  }
  static Redirect(link, target) {
    try {
      target = C.Str(target, "_self");
      if (target == "_self") {
        window.location = Web.GetFullUrl(link);
      } else {
        window.open(Web.GetFullUrl(link), target);
      }
      return true;
    } catch (e) {
      console.log(e.message);
    }
    return false;
  }
  static Refresh() {
    window.location.reload();
  }
  static ReadURLImage(file, img) {
    var reader = new FileReader();
    reader.onload = function (e) {
      if (img != null) {
        img.setAttribute("src", e.target.result);
      }
    };
    reader.readAsDataURL(file);
  }
  static GetBase64(file, callback) {
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(true, e.target.result);
    };
    reader.onerror = function (e) {
      callback(false, e);
    };
    reader.readAsDataURL(file);
  }
  static GetFileBase64(sender, index, callback) {
    if (sender) {
      if (index < sender.files.length) {
        Web.GetBase64(sender.files[index], callback);
      } else {
        callback(false, Lang.Get("Index Error!"));
      }
    } else {
      callback(false, Lang.Get("Element Error!"));
    }
  }
  static GetUrl(url) {
    url = C.Str(url).toLowerCase();
    var chars = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "r",
      "s",
      "t",
      "u",
      "v",
      "y",
      "z",
      "q",
      "x",
      "w",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    var v = "";
    for (var i = 0; i < url.length; i++) {
      if (url[i] != "" && url[i] != String.fromCharCode(775)) {
        var isIn = false;
        for (var j = 0; j < chars.length; j++) {
          if (url[i] == chars[j]) {
            v += url[i];
            isIn = true;
            break;
          }
        }
        if (!isIn) {
          switch (url[i]) {
            case "ç":
              v += "c";
              break;
            case "ğ":
              v += "g";
              break;
            case "ı":
              v += "i";
              break;
            case "ö":
              v += "o";
              break;
            case "ş":
              v += "s";
              break;
            case "ü":
              v += "u";
              break;
            default:
              v += "-";
              break;
          }
        }
      }
    }
    while (v.indexOf("--") > -1) {
      v = v.replace(/--/g, "-");
    }
    if (v.length > 0) {
      if (v.length > 1 && v[0] == "-") {
        v = v.substring(1);
      }
      try {
        if (C.Str(v.substring(v.length - 1, v.length)) == "-") {
          v = v.substring(0, v.length - 1);
        }
      } catch (e) {
        console.log(e.message);
      }
    }
    return v;
  }
  static Base64Split(v) {
    v = C.Str(v);
    if (v.indexOf("data:") == 0 && v.indexOf(";base64,") > 0) {
      var m = v.match("data:(?<type>.+?);base64,(?<data>.+)");
      if (m != null) {
        return { Type: m.groups.type, Data: m.groups.data };
      }
    }
    return null;
  }
static Base64Valid(v) {
    if (C.Str(v) !=  '') {
        var m = Web.Base64Split(v);
        if (m != null) {
            return (m.Type != '' && m.Data != '');
        }
    }
    return false;
}
    static FileValid(v) {
        if (C.Str(v) != '') {
            return !Web.Base64Valid(v);
        }
        return false;
    }
  static QueryString(key, def) {
    key = Text.ToLowerEn(key);
    var q = C.Str(window.location.search);
    if (q.indexOf("?") == 0) {
      q = q.substring(1);
    }
    var p = q.split("&");
    for (var i = 0; i < p.length; i++) {
      var kv = p[i].split("=");
      if (Text.ToLowerEn(kv[0]) == key) {
        if (kv.length == 2) {
          return decodeURIComponent(kv[1]);
        }
        break;
      }
    }
    return def;
  }
  static QueryStringReplace(list) {
    var q = C.Str(window.location.search);
    if (q.indexOf("?") == 0) {
      q = q.substring(1);
    }

    for (var i = 0; i < list.length; i++) {
      var r = { Key: list[i][0], Value: list[i][1] };
      r.Key = Text.ToLowerEn(r.Key);
      var p = q.split("&");
      q = "";
      var isIn = false;
      for (var j = 0; j < p.length; j++) {
        if (q != "") {
          q += "&";
        }
        if (Text.ToLowerEn(p[j].split("=")[0]) == r.Key) {
          q += r.Key + "=" + r.Value;
          isIn = true;
        } else {
          q += p[j];
        }
      }
      if (!isIn) {
        if (q != "") {
          q += "&";
        }
        q += r.Key + "=" + r.Value;
      }
    }
    q = (q.length > 0 ? "?" : "") + q;
    return window.location.pathname + q;
  }

  static EnterForm(form, senderId) {
    if (typeof form === "string") {
      form = document.querySelector(form);
    }
    appData.each(
      form.querySelectorAll(
        'input[type="email"], input[type="tel"], input[type="tc"], input[type="text"], input[type="password"]'
      ),
      function () {
        this.addEventListener("keypress", function (event) {
          if (event.which == 13) {
            event.preventDefault();
            document.querySelector("#" + senderId).click();
          }
        });
      }
    );
  }
  static OutHtmlTag(sender) {
    var c = sender.cloneNode();

    var div = document.createElement("DIV");
    div.appendChild(c);
    return div.innerHTML;
  }
  static ClipboardText(value, allowMsg) {
    var d = document.createElement("DIV");
    d.innerHTML = '<div id="clipboard-copy"><textarea></textarea></div>';
    d.style.opacity = 0;
    d.style.position = "fixed";
    d.style.left = "-100%";

    var div = d.firstElementChild;
    document.querySelector("body").appendChild(div);
    var input = document.querySelector("#clipboard-copy textarea");
    input.value = value;
    input.select();
    document.execCommand("copy");
    document.querySelector("#clipboard-copy").remove();
    if (allowMsg == null) {
      allowMsg = true;
    }
    if (C.Bool(allowMsg)) {
      Msg.Info("Kopyalandı!");
    }
  }
  static ClipboardStyleText(element, allowMsg) {
    var doc = document,
      range,
      selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    if (allowMsg == null) {
      allowMsg = true;
    }
    if (C.Bool(allowMsg)) {
      Msg.Info("Kopyalandı!");
    }
  }
  static SoundPlay(name) {
    var d = document.createElement("DIV");
    d.innerHTML = '<div id="sound" style="display:none;"></div>';
    var div = d.firstElementChild;
    if (document.querySelectorAll("#sound").length == 0) {
      document.querySelector("body").appendChild(div);
    }

    document.querySelector("#sound").innerHTML =
      "<audio autoplay>" +
      '   <source src="/assets/sound/' +
      name +
      '.mp3" type="audio/mpeg" />' +
      '   <source src="/assets/sound/' +
      name +
      '.ogg" type="audio/ogg" />' +
      //'   <embed src="/assets/sound/' + name + '.mp3" hidden="true" autostart="true" loop="false"  />' +
      "</audio>";
  }
  static YoutubeUrlToEmbed(url) {
    url = C.Str(url);
    if (url.toLowerCase().indexOf("youtube.com/embed/") > -1) {
      return url;
    }
    var query = url.split("?");
    if (query.length == 2) {
      var p = query[1].split("&");
      var start = "";
      url = "";
      for (var i = 0; i < p.length; i++) {
        var kv = p[i].split("=");
        if (kv.length == 2) {
          if (kv[0] == "t") {
            start = "&start=" + kv[1];
          }
          if (kv[0] == "v") {
            url = "https://www.youtube.com/embed/" + kv[1];
          }
        }
      }
      if (url != "") {
        return url + start;
      }
    }
    return "";
  }
  static WebPConvert(base64, quality, callback) {
    try {
      if (quality == null) {
        quality = 1.0;
      }
      var img = new Image();
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var webP = canvas.toDataURL("image/webp", quality);
        callback(true, webP);
      };
      img.src = base64;
    } catch (e) {
      callback(false, "");
    }
  }

  static GetFileName(path) {
    var p = C.Str(path).split("/");
    var file = p[p.length - 1];
    return file.substring(0, file.lastIndexOf("."));
  }
  static GetExtension(path) {
    var p = C.Str(path).split("/");
    var file = p[p.length - 1];
    return file
      .substring(file.lastIndexOf("."))
      .toLowerCase()
      .replace(/ı/g, "i");
  }
  static GetMimeType(v) {
    v = C.Str(v);
    if (v.indexOf("data:") == 0 && v.indexOf(";base64,") > 0) {
      var m = v.match("data:(?<type>.+?);base64");
      if (m != null) {
        return m.groups.type;
      }
    }
    return null;
  }
  static FindExtension(mimeType) {
    mimeType = C.Str(mimeType).toLowerCase().replace(/ı/g, "i");
    for (var i = 0; i < Enums.MIMETYPE.length; i++) {
      var r = Enums.MIMETYPE[i];
      if (r.Extension.contains(mimeType)) {
        return r;
      }
    }
    return null;
  }
  static GetFileNameExtension(path) {
    var p = C.Str(path).split("/");
    var file = p[p.length - 1];
    return {
      Name: file.substring(0, file.lastIndexOf(".")),
      Extension: file.substring(file.lastIndexOf(".")),
    };
  }
  static Base64Blob(base64, sliceSize) {
    var b = Web.Base64Split(base64);
    sliceSize = C.Int(sliceSize, 512);
    var byteCharacters = atob(b.Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: b.Type });
    return blob;
  }

  static GetFullUrl(link) {
    // Site urlsini çeker
    var url = C.Str(link);
    if (
      url.indexOf("#") == 0 ||
      url.toLowerCase().indexOf("tel:") == 0 ||
      url.toLowerCase().indexOf("sms:") == 0 ||
      url.toLowerCase().indexOf("mailto:") == 0 ||
      url.toLowerCase().indexOf("geo:") == 0 ||
      url.toLowerCase().indexOf("market:") == 0
    ) {
      return url;
    }
    if (url.split("#")[0].split("?")[0].indexOf("://") > -1) {
      return url;
    }
    if (Web.Base64Split(url) != null) {
        return url;
    }
    if (url.length >= 2) {
      if (C.Str(url[0]) + C.Str(url[1]) == "~/") {
        url = url.substring(2);
      } else if (C.Str(url[0]) == "/") {
        url = url.substring(1);
      }
    } else if (url == "/") {
      url = "";
    }
    return appData.base + url;
  }
  static Media(link, def, stopVersion, customBase) {
    stopVersion = C.Bool(stopVersion);
    var url = C.Str(link);
    if (url == "") {
      return C.Str(def) != "" ? Web.Site(def) : "";
    }
    if (url == "#") {
      return "#";
    }
    if (url.split("#")[0].split("?")[0].indexOf("://") > -1) {
      return url;
    }
    if (Web.Base64Split(url) != null) {
      return url;
    }
    if (url == def && def.indexOf("/empty?") > -1) {
      stopVersion = true;
    }
    if (url.length >= 2) {
        if (C.Str(url[0]) + C.Str(url[1]) == "~/") { url = url.substring(2); }
        else if (C.Str(url[0]) == "/") { url = url.substring(1); }
      //url.split('/')[0];  ürün, vs ise img.domain şeklinde çek
    }
    var getVersion = function (l) {
      if (!stopVersion) {
        var d = "";
        if (l.indexOf("?") > -1) {
          var q = l.split("?")[1].split("&");
          if (l.indexOf("#") > -1) {
            d = "#" + l.split("?")[1].split("#")[1];
          }

          var p = "?v=" + Rnd.Text(5, false);
          for (var i = 0; i < q.length; i++) {
            if (q[i] != "" && q[i] != "v" && q[i].indexOf("v=") == -1) {
              p += "&" + q[i];
            }
          }
          l = l.split("?")[0] + p + d;
        } else {
          if (l.indexOf("#") > -1) { d = "#" + l.split("#")[1]; }
          l += "?v=" + Rnd.Text(5, false) + d;
        }
      }
      return l;
    };
    return C.Str(customBase, appData.mediaBase + "f/") + getVersion(url);
  }
  static Image(link, def, size, isTransparent) {
    var url = C.Str(link);
    if (url == "") { return C.Str(def) != "" ? Web.Site(def) : ""; }
    if (url == "#") { return "#"; }
    if (url.split("#")[0].split("?")[0].indexOf("://") > -1) { return url; }
    if (Web.Base64Split(url) != null) { return url; }
    if (url.length >= 2) {
        if (C.Str(url[0]) + C.Str(url[1]) == "~/") { url = url.substring(2); }
        else if (C.Str(url[0]) == "/") { url = url.substring(1); }
    }
    return (appData.mediaBase + (C.Bool(isTransparent) ? "t/" : "s/") + size + "/" + url);
  }
  static Site(link, def, stopVersion) {
    return Web.Media(link, def, stopVersion, appData.base);
  }
  //static Classic(link) {
  //  var url = C.Str(link);
  //  if (
  //    url.indexOf("#") == 0 ||
  //    url.toLowerCase().indexOf("tel:") == 0 ||
  //    url.toLowerCase().indexOf("sms:") == 0 ||
  //    url.toLowerCase().indexOf("mailto:") == 0 ||
  //    url.toLowerCase().indexOf("geo:") == 0 ||
  //    url.toLowerCase().indexOf("market:") == 0
  //  ) {
  //    return url;
  //  }
  //  if (url.split("#")[0].split("?")[0].indexOf("://") > -1) {
  //    return url;
  //  }
    //    if(Web.Base64Split(url) != null) {
    //    return url;
    //}
  //  if (url.length >= 2) {
  //    if (C.Str(url[0]) + C.Str(url[1]) == "~/") {
  //      url = url.substring(2);
  //    } else if (C.Str(url[0]) == "/") {
  //      url = url.substring(1);
  //    }
  //  } else if (url == "/") {
  //    url = "";
  //  }
  //  return appData.classicBase + url;
  //  },
    static Server(link, def) {
        var url = C.Str(link, def);
    if (
      url.indexOf("#") == 0 ||
      url.toLowerCase().indexOf("tel:") == 0 ||
      url.toLowerCase().indexOf("sms:") == 0 ||
      url.toLowerCase().indexOf("mailto:") == 0 ||
      url.toLowerCase().indexOf("geo:") == 0 ||
      url.toLowerCase().indexOf("market:") == 0
    ) {
      return url;
    }
    if (url.split("#")[0].split("?")[0].indexOf("://") > -1) {
      return url;
    }
    if (Web.Base64Split(url) != null) {
        return url;
    }
    if (url.length >= 2) {
      if (C.Str(url[0]) + C.Str(url[1]) == "~/") {
        url = url.substring(2);
      } else if (C.Str(url[0]) == "/") {
        url = url.substring(1);
      }
    } else if (url == "/") {
      url = "";
    }
    return appData.serverBase + url;
  }
}

// Mesaj Verme
export class Msg {
  constructor() {}
  static Success(message, title) {
    Popup.Success(message, title);
  }
  static Info(message, title) {
    Popup.Info(message, title);
  }
  static Warning(message, title) {
    Popup.Warning(message, title);
  }
  static Error(message, title) {
    Popup.Danger(message, title);
  }
  static Danger(message, title) {
    Popup.Danger(message, title);
  }
}

export class Popup {
  constructor() {}
  static Success(message, title, btnText, timer, callback) {
    btnText = Lang.Get(C.Str(btnText));
    timer = C.Int(timer, 2000);
    title = Lang.Get(C.Str(title, "İşlem başarılı"));
    message = Lang.Get(message);
    timer += message.length * 100;

    SweetAlert2.fire({
      //position: 'top-end',
      title: title,
      text: message,
      //type: 'success',
      icon: "success",
      showConfirmButton: btnText != "",
      timer: timer,
      confirmButtonText: btnText,
    }).then(function (e) {
      if (callback != null) {
        callback(e.dismiss);
      }
    });
  }
  static Info(message, title, btnText, timer, callback) {
    btnText = Lang.Get(C.Str(btnText));
    timer = C.Int(timer, 2000);
    title = Lang.Get(C.Str(title, "Bilgilendirme"));
    message = Lang.Get(message);
    timer += message.length * 100;

    SweetAlert2.fire({
      //position: 'top-end',
      title: title,
      text: message,
      //type: 'info',
      icon: "info",
      showConfirmButton: btnText != "",
      timer: timer,
      confirmButtonText: btnText,
    }).then(function (e) {
      if (callback != null) {
        callback(e.dismiss);
      }
    });
  }
  static Warning(message, title, btnText, timer, callback) {
    btnText = Lang.Get(C.Str(btnText));
    timer = C.Int(timer, 3000);
    title = Lang.Get(C.Str(title, "Uyarı"));
    message = Lang.Get(message);
    timer += message.length * 100;

    SweetAlert2.fire({
      //position: 'top-end',
      title: title,
      text: message,
      //type: 'warning',
      icon: "warning",
      showConfirmButton: btnText != "",
      timer: timer,
      confirmButtonText: btnText,
    }).then(function (e) {
      if (callback != null) {
        callback(e.dismiss);
      }
    });
  }
  static Danger(message, title, btnText, timer, callback) {
    btnText = Lang.Get(C.Str(btnText, "Tamam"));
    timer = C.Int(timer, 3000);
    title = Lang.Get(C.Str(title, "Hata"));
    message = Lang.Get(message);
    //timer += message.length * 100;

    SweetAlert2.fire({
      //position: 'top-end',
      title: title,
      text: message,
      //type: 'error',
      icon: "error",
      showConfirmButton: btnText != "",
      //timer: timer,
      confirmButtonText: btnText,
    }).then(function (e) {
      if (callback != null) {
        callback(e.dismiss);
      }
    });
  }

  static Confirm(callback, args) {
    if (args == null) {
      args = {};
    }

    SweetAlert2.fire({
      title: Lang.Get(C.Str(args.Title, "Emin Misiniz?")),
      text: Lang.Get(
        C.Str(args.Content, "Bu işlemi yapmak istediğinize emin misiniz?")
      ),
      //type: 'info',
      icon: "info",
      showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      //cancelButtonColor: '#d33',
      confirmButtonText: Lang.Get(C.Str(args.Btn1, "Onayla")),
      cancelButtonText: Lang.Get(C.Str(args.Btn2, "İptal")),
      confirmButtonClass: "btn btn-danger ",
      cancelButtonClass: "btn btn-primary",
      buttonsStyling: false,
      reverseButtons: false,
    }).then(function (result) {
      if (result.value) {
        if (Web.ConnectDetect(false)) {
          callback(function () {
            Msg.Success(
              C.Str(args.SuccessContent, "İşleminiz gerçekleştirildi"),
              C.Str(args.SuccessTitle, "İşlem Başarılı")
            );
          });
        }
      }
      //else if (result.dismiss === swal.DismissReason.cancel) {
      //    swal(
      //        'İptal',
      //        'Silme işlemi iptal edildi',
      //        'error'
      //    )
      //}
    });
  }
  static Delete(callbackDelete, args) {
    if (args == null) {
      args = {};
    }

    SweetAlert2.fire({
      title: Lang.Get(C.Str(args.Title, "Silme Onayı")),
      text: Lang.Get(
        C.Str(args.Content, "Seçilen kaydı silmek istediğinize emin misiniz?")
      ),
      //type: 'warning',
      icon: "warning",
      showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      //cancelButtonColor: '#d33',
      confirmButtonText: Lang.Get(C.Str(args.Btn1, "Sil")),
      cancelButtonText: Lang.Get(C.Str(args.Btn2, "İptal")),
      confirmButtonClass: "btn btn-danger column-45",
      cancelButtonClass: "btn btn-primary column-45 space",
      buttonsStyling: false,
      reverseButtons: false,
    }).then(function (result) {
      if (result.value) {
        if (Web.ConnectDetect(false)) {
          callbackDelete(function () {
            Msg.Success(
              C.Str(args.SuccessContent, "Silme işleminiz gerçekleştirildi"),
              C.Str(args.SuccessTitle, "Silindi")
            );
          });
        }
      }
      //else if (result.dismiss === swal.DismissReason.cancel) {
      //    swal(
      //        'İptal',
      //        'Silme işlemi iptal edildi',
      //        'error'
      //    )
      //}
    });
  }
  static Support(data) {
    var html = document.createElement("DIV");
    var confirmButton = false;
    var type = data.Type;
    if (type == null) {
      type = SupportType.Text;
    }
    var width = "32em";
    switch (type) {
      case SupportType.Text: // data = { Type: SupportType.Text, Text: 'Bilgi Mesajı' };
        html.innerText = C.Str(data.Text);
        confirmButton = true;
        break;
      case SupportType.Video: // data = { Type: SupportType.Video, Src: '', Text : 'Bilgi Mesajı' }
        width = "60em";
        data.Src = C.Str(data.Src);
        var src = Text.Rep(
          Text.Rep(data.Src.split("&")[0], "https://", "//"),
          "http://",
          "//"
        );
        src = Text.Rep(
          Text.Rep(
            Text.Rep(
              Text.Rep(src, "//www.youtube.com/", "/"),
              "//m.youtube.com/",
              "/"
            ),
            "//youtu.be/",
            ""
          ),
          "//www.youtube-nocookie.com/",
          "/"
        );
        src = Text.Rep(
          Text.Rep(Text.Rep(src, "/watch?v=", ""), "/embed/", ""),
          "?t=",
          "?start="
        );

        if (data.Src.split("&").length > 1) {
          var l = data.Src.split("&");
          src += (src.indexOf("?") > -1 ? "%" : "?") + l[1];
          for (var i = 2; i < l.length; i++) {
            src += "&" + l[i];
          }
        }
        src = Text.Rep(src, "&t=", "&start=");

        html.appendChild(
          appData.create(
            '<iframe width="100%" height="504" src="https://www.youtube.com/embed/' +
              src +
              '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
          )
        );
        var p = document.createElement("P");
        p.innerText = C.Str(data.Text);
        html.appendChild(p);
        break;
      case SupportType.Carousel:
        //data = { Type: SupportType.Video, List : [{ Src: '', Title: '', Text : '' }, { Src: '', Title: '', Text : '' }] };
        html.appendChild(
          appData.create(
            `<div id="supportCarousel" class="carousel slide" data-ride="carousel">
				<ol class="carousel-indicators"></ol><div class="carousel-inner"></div>
                <a class="carousel-control-prev" href="#supportCarousel" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="sr-only">Geri</span></a>
                <a class="carousel-control-next" href="#supportCarousel" role="button" data-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span><span class="sr-only">İleri</span></a>
                </div>`
          )
        );
        for (let i = 0; i < data.List.length; i++) {
          var r = data.List[i];
          r.Title = C.Str(r.Title);
          r.Text = C.Str(r.Text);
          html
            .querySelector(".carousel-indicators")
            .appendChild(
              appData.create(
                '<li data-target="#supportCarousel" data-slide-to="' +
                  i +
                  '" ' +
                  (i == 0 ? 'class="active"' : "") +
                  "></li>"
              )
            );
          var div = appData.create(
            '<div class="carousel-item ' +
              (i == 0 ? "active" : "") +
              '">' +
              '<img class="d-block" style="width:100%" src="' +
              Web.GetFullUrl(r.Src) +
              '" alt="Görsel ' +
              (i + 1) +
              '">' +
              '<div class="carousel-caption d-none d-md-block">' +
              (r.Title.length > 0
                ? '<h5 class="text-white m-0">' + C.Str(r.Title) + "</h5>"
                : "") +
              (r.Text.length > 0
                ? '<p class="text-white">' + r.Text + "</p>"
                : "") +
              "</div></div>"
          );
          html.querySelector(".carousel-inner").appendChild(div);
        }
        break;
    }
    SweetAlert2.fire({
      width: width,
      position: "top-center",
      html: html,
      type: confirmButton ? "info" : "",
      icon: confirmButton ? "info" : "",
      showConfirmButton: confirmButton,
      timer: 0,
      confirmButtonText: Lang.Get(C.Str("Tamam")),
    }); //.then(function (e) { if (callback != null) { callback(e.dismiss); } });
  }
}

// Button, Div-Block, Page
export class Wait {
  static IsButton(sender) {
    if (sender == null) {
      return false;
    }
    return C.Bool(sender.getAttribute("data-wait"));
  }
  static IsBlock(sender) {
    if (sender == null) {
      return false;
    }
    if (sender.querySelector(".block-wait")) {
      return true;
    }
    return false;
  }
  static IsPage() {
    if (window.querySelector("body.page-wait")) {
      return true;
    }
    return false;
  }
  static Button(sender, state) {
    if (sender == null) {
      return false;
    }
    var css = "wait-btn";
    var isWait = C.Bool(sender.getAttribute("data-wait"));
    if (C.Bool(state)) {
      // Bekle
      if (!isWait) {
        //sender.style.position = 'relative';
        sender.setAttribute("data-wait", true);
        appData.each(sender, function () {
          this.setAttribute("disabled", "disabled");
        });
        sender.classList.add(css);
        let waiting = appData.create(
          '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
        );
        sender.insertBefore(waiting, sender.firstChild);
        return true;
      }
    } else {
      if (isWait) {
        sender.removeAttribute("data-wait");
        appData.each(sender, function () {
          this.removeAttribute("disabled");
        });
        let waiting = sender.querySelector(".spinner-border");
        if (waiting) {
          waiting.remove();
        }
        sender.classList.remove(css);
        return true;
      }
    }
    return false;
  }
  static Block(sender, state) {
    if (sender == null) {
      return false;
    }
    var css = "wait-block";
    if (C.Bool(state)) {
      // Bekle
      sender.classList.add(css);
      var div = appData.create(
        '<div class="spinner"><div class="spinner-border" role="status"></div></div>'
      );
      setTimeout(function () {
        sender.appendChild(div);
      }, 10);
    } else {
      sender.classList.remove(css);
      var l = sender.querySelectorAll(".spinner");
      for (var i = 0; i < l.length; i++) {
        l[i].remove();
      }
    }
  }
  static Page(state) {
    var css = "wait-page";
    var body = document.querySelector("body");
    if (C.Bool(state)) {
      // Bekle
      body.classList.add(css);
      var div = appData.create(
        '<div class="spinner"><div class="spinner-border" role="status"></div></div>'
      );
      body.appendChild(div);
    } else {
      body.classList.remove(css);
      var l = body.querySelectorAll(".spinner");
      for (var i = 0; i < l.length; i++) {
        l[i].remove();
      }
    }
  }
}

// Ajax İşlemleri
export class Ajax {
  constructor() {}
  static GetJson(url, callback) {
    if (C.Str(url) == "") {
      console.error("Ajax-GetJson:Url boş olamaz!");
      return;
    }
    axios
      .get(Web.Server(url))
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error);
      });
    //.finally(function() { this.loading = false })
  }
  static Api(method, url, data, wait, version) {
    if (C.Str(url) == "") {
      Msg.Error("Ajax:Url boş olamaz!");
      return;
    }
    if (url.indexOf("//") == -1) {
      url = "/api/" + C.Str(version, "v1") + "/" + url;
    }
    // Eğer dış link değil ise => /api/v1/ + link
    // /swagger/index.html
    if (method == MethodType.Get && data != null && data != {}) {
      console.error("Get metodunda data gönderemezsiniz!");
      data = {};
    }
    if (data == null) {
      data = {};
    }

    return new AjaxPromise(function (ok, err, end, isAlert) {
    try {
        const loginType = C.Int(localStorage.getItem('LoginScreen'));
        var token = '';
        switch (loginType) {
            case Enums.LOGIN_TYPE.USER:
                token = appData.user.token;
                break;
            case Enums.LOGIN_TYPE.CUSTOMER:
                token = appData.customer.token;
                break;
        }

        const headers = {
          //'Content-Type': 'multipart/form-data',
          "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + token,
        };
        axios({
          url: Web.Server(url),
          method: method,
          data: data,
          headers: headers,
        })
          .then(function (response) {
            //var r = J.Parse(response.data, '{}');
            var r = response.data;
            r.status = C.Int(r.status); // 2: Başarılı
            r.message = C.Str(r.message); // Mesaj
            if (r.status == 2) {
              ok(r.data, r.message);
            } else {
              if (isAlert) {
                switch (r.Status) {
                  case 0:
                    Msg.Info(r.message);
                    break;
                  case 3:
                    Msg.Warning(r.message);
                    break;
                  case 1:
                  default:
                    Msg.Error(r.message);
                    break;
                }
              }
              err(r);
            }
            if (end != null) {
              end(r.status);
            }
            if (wait != null) {
              wait();
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 401) {
                  if (error.response.statusText == "Unauthorized") {
                  // Login Başarısız;
                  var lastUrl = ""; //window.location.href.replace(appData.base, '');
                  //if (lastUrl.indexOf('?') > -1) {
                  //}
                  if (lastUrl != "") {
                    lastUrl = "?p=" + lastUrl;
                  }
                  appData.user.logout();
                  appData.customer.logout();
                  Web.Redirect(Links.Login + lastUrl);
                  return;
                } else if (error.response.statusText == "PermissionError") {
                  Msg.Warning("Yetkiniz yetersiz!");
                  return;
                }
              }
            }
            Msg.Error("Bilinmeyen bir hata oluştu!");
            console.error(error.message);
            if (end != null) {
              end(1); /* Error */
            }
            if (wait != null) {
              wait();
            }
          });
      } catch (e) {
        Msg.Error("Bilinmeyen bir hata oluştu!");
        console.error(e.message);
        if (end != null) {
          end(1); /* Error */
        }
        if (wait != null) {
          wait();
        }
      }
    });
  }
}

export class Exporter {
  constructor() {}

  static ExcludeImg(p) {
    // Removes all img tags
    var _patt = /(\s+alt\s*=\s*"([^"]*)"|\s+alt\s*=\s*'([^']*)')/i;
    return p.replace(/<img[^>]*>/gi, function fnc(x) {
      var res = _patt.exec(x);
      if (res !== null && res.length >= 2) {
        return res[2];
      } else {
        return "";
      }
    });
  }
  static ExcludeInputs(p) {
    // Removes input params
    var _patt = /(\s+value\s*=\s*"([^"]*)"|\s+value\s*=\s*'([^']*)')/i;
    return p.replace(/<input[^>]*>|<\/input>/gi, function fnc(x) {
      var res = _patt.exec(x);
      if (res !== null && res.length >= 2) {
        return res[2];
      } else {
        return "";
      }
    });
  }
  static ExcludeLinks(p) {
    // Removes all link tags
    return p.replace(/<a[^>]*>|<\/a>/gi, "");
  }

  static ToExcel(tableList, settings) {
    if (settings == null) {
      settings = {};
    }
    settings.FileName = C.Str(settings.FileName, "Excel File") + ".xls";
    settings.WorkSheetName = C.Str(settings.WorkSheetName, "Worksheet");
    settings.SheetName = C.Str(settings.SheetName, "Sheet");

    settings.AllowLink = C.Bool(settings.AllowLink);
    settings.AllowImg = C.Bool(settings.AllowImg);
    settings.AllowInput = C.Bool(settings.AllowInput);

    var getTd = function (parent, callback, hiddenColumns) {
      var html = "";
      if (parent) {
        var trList = parent.querySelectorAll("tr:not(.no-export)");
        if (trList) {
          for (var i = 0; i < trList.length; i++) {
            var tr = trList[i];
            html += "<tr>";
            //var tdList = tr.querySelectorAll('td:not(.no-export),th:not(.no-export)');
            var tdList = tr.querySelectorAll("td,th");
            for (var j = 0; j < tdList.length; j++) {
              var td = tdList[j];
              if (!tr.classList.contains("child")) {
                var isIn = false;
                for (var k = 0; k < hiddenColumns.length; k++) {
                  if (j == hiddenColumns[k]) {
                    isIn = true;
                    break;
                  }
                }
                if (isIn) {
                  continue;
                }
              }

              if (td.classList.contains("no-export")) {
                continue;
              }

              var hiddenList = td.querySelectorAll(".no-export");
              for (let k = 0; k < hiddenList.length; k++) {
                hiddenList[k].remove();
              }
              var tdHtml = callback(td);

              if (!settings.AllowImg) {
                tdHtml = Exporter.ExcludeImg(tdHtml);
              }
              if (!settings.AllowInput) {
                tdHtml = Exporter.ExcludeInputs(tdHtml);
              }
              if (!settings.AllowLink) {
                tdHtml = Exporter.ExcludeLinks(tdHtml);
              }

              html += tdHtml;
            }
            html += "</tr>";
          }
        }
      }
      return html;
    };
    var getTable = function (t) {
      var tempRows = "";
      if (t) {
        var hiddenColumns = [];
        var thList = t.querySelectorAll("thead > tr > th");
        for (var i = 0; i < thList.length; i++) {
          if (thList[i].classList.contains("no-export")) {
            hiddenColumns.push(i);
          }
        }

        tempRows += getTd(
          t.querySelector("thead"),
          function (cell) {
            //cell.classList - cell.style
            return "<th>" + cell.innerHTML + "</th>";
          },
          []
        );
        tempRows += getTd(
          t.querySelector("tbody"),
          function (cell) {
            //cell.classList - cell.style
            return "<td>" + cell.innerHTML + "</td>";
          },
          hiddenColumns
        );
        tempRows += getTd(
          t.querySelector("tfoot"),
          function (cell) {
            //cell.classList - cell.style

            return "<th>" + cell.innerHTML + "</th>";
          },
          []
        );
      }
      return tempRows;
    };

    if (tableList) {
      var tableRows = [];
      if (Array.isArray(tableList)) {
        // Çoklu Tablo
        for (var i = 0; i < tableList.length; i++) {
          tableRows.push(getTable(tableList[i]));
        }
      } else {
        tableRows.push(getTable(tableList));
      }

      // Tablo Satıları Hazır
      var fullTemplate = "";
      var utf8Heading =
        '<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
      var template = {
        head:
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">' +
          utf8Heading +
          "<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets>",
        sheet: {
          head: "<x:ExcelWorksheet><x:Name>",
          tail: "</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>",
        },
        mid: "</x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>",
        table: {
          head: "<table>",
          tail: "</table>",
        },
        foot: "</body></html>",
      };

      fullTemplate = template.head;

      if (Array.isArray(tableRows)) {
        for (let i in tableRows) {
          //fullTemplate += template.sheet.head + '{worksheet' + i + '}' + template.sheet.tail;
          fullTemplate +=
            template.sheet.head + settings.SheetName + i + template.sheet.tail;
        }
      }

      fullTemplate += template.mid;

      if (Array.isArray(tableRows)) {
        for (let i in tableRows) {
          fullTemplate +=
            template.table.head + "{table" + i + "}" + template.table.tail;
        }
      }

      fullTemplate += template.foot;

      var ctx = {
        worksheet: settings.WorkSheetName,
        sheetName: settings.SheetName,
      };

      if (Array.isArray(tableRows)) {
        for (let i in tableRows) {
          ctx["table" + i] = tableRows[i];
        }
      }

      var format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p]; // worksheet, sheetName, tableX gördüğün yeri ctxteki değerler ile replace yap
        });
      };

      // Export Aşaması

      var isIE = /*@cc_on!@*/ false || !!document.documentMode; // this works with IE10 and IE11 both :)
      //if (typeof msie !== 'undefined' && msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // this works ONLY with IE 11!!!
      if (isIE) {
        if (typeof Blob !== "undefined") {
          //use blobs if we can
          fullTemplate = format(fullTemplate, ctx); // with this, works with IE
          fullTemplate = [fullTemplate];
          //convert to array
          var blob1 = new Blob(fullTemplate, { type: "text/html" });
          window.navigator.msSaveBlob(blob1, settings.FileName);
          return true;
        } else {
          //otherwise use the iframe and save
          //requires a blank iframe on page called txtArea1
          var frame = document.createElement("iframe");
          frame.style.width = "640px";
          frame.style.height = "480px";
          //d.style.opacity = 0;
          //d.style.position = "fixed";
          //d.style.left = "-100%";
          document.body.appendChild(frame);

          frame.document.open("text/html", "replace");
          frame.document.write(format(fullTemplate, ctx));
          frame.document.close();
          frame.focus();
          //var sa = frame.document.execCommand(
          //  "SaveAs",
          //  true,
          //  settings.FileName
          //);
          setTimeout(function () {
            document.body.removeChild(frame);
          }, 1000);
          return true;
        }
      } else {
        var blob = new Blob([format(fullTemplate, ctx)], {
          type: "application/vnd.ms-excel",
        });
        window.URL = window.URL || window.webkitURL;
        var link = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.download = settings.FileName;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return true;
      }
    }
    return false;
  }
  static ToPrint(tableList) {
    if (tableList) {
      var div = document.createElement("div");
      if (Array.isArray(tableList)) {
        // Çoklu Tablo
        for (var i = 0; i < tableList.length; i++) {
          div.innerHTML += tableList[i].outerHTML;
        }
      } else {
        div.innerHTML = tableList.outerHTML;
      }
      var frame = window.open();
      frame.document.write(
        `<html>
            <head>
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width,initial-scale=1">
                <meta name="robots" content="noindex" />
                <meta name="robots" content="nofollow" />
                <meta name="robots" content="noarchive" />
                <meta name="robots" content="noodp" />
                <link rel="stylesheet" href="/assets/css/print.css"/>
            </head>
            <body onload="">`
      ); // Print css
      frame.document.write(div.innerHTML);
      frame.document
        .write(`<script type="text/javascript">window.onload = function () {
                var tableList = document.getElementsByTagName('table');
                for (var i = 0; i < tableList .length; i++) {
                    var thList = tableList[i].querySelectorAll('thead > tr > th');
                    for (var j = 0; j < thList.length; j++) {
                        if (thList[j].classList.contains('no-export')) {
                            var tdList = tableList[i].querySelectorAll('tbody > tr:not(.child) > td:nth-child(' + (j + 1) + ')');
                            for (var k = 0; k < tdList.length; k++) {
                                tdList[k].classList.add('hide');
                            }
                        }
                    }
                }
                window.print(); 
                window.close();
            }</script > `);
      //frame.document.write('<script type="text/javascript">window.onload = function () { window.print(); window.close(); }</script>');
      frame.document.write("</body></html>");
      frame.document.close();
      return true;
    }

    return false;
  }
  static ToCopy(tableList) {
    var getTd = function (parent, callback, hiddenColumns) {
      var text = "";
      if (parent) {
        var trList = parent.querySelectorAll("tr:not(.no-export)");
        if (trList) {
          for (let i = 0; i < trList.length; i++) {
            var tr = trList[i];
            //text += '';
            //var tdList = tr.querySelectorAll('td:not(.no-export),th:not(.no-export)');
            var tdList = tr.querySelectorAll("td,th");
            for (var j = 0; j < tdList.length; j++) {
              var td = tdList[j];
              if (!tr.classList.contains("child")) {
                var isIn = false;
                for (var k = 0; k < hiddenColumns.length; k++) {
                  if (j == hiddenColumns[k]) {
                    isIn = true;
                    break;
                  }
                }
                if (isIn) {
                  continue;
                }
              }

              if (td.classList.contains("no-export")) {
                continue;
              }
              var hiddenList = td.querySelectorAll(".no-export");

              for (let k = 0; k < hiddenList.length; k++) {
                hiddenList[k].remove();
              }
              var tdText = callback(td);
              text += tdText + (j + 1 < tdList.length ? "\t" : ""); // boşluk
            }
            text += "\r\n";
          }
        }
      }
      return text;
    };
    var getTable = function (t) {
      var tempRows = "";
      if (t) {
        var hiddenColumns = [];
        var thList = t.querySelectorAll("thead > tr > th");
        for (let i = 0; i < thList.length; i++) {
          if (thList[i].classList.contains("no-export")) {
            hiddenColumns.push(i);
          }
        }

        tempRows += getTd(
          t.querySelector("thead"),
          function (cell) {
            return cell.innerText;
          },
          []
        );
        tempRows += getTd(
          t.querySelector("tbody"),
          function (cell) {
            return cell.innerText;
          },
          hiddenColumns
        );
        tempRows += getTd(
          t.querySelector("tfoot"),
          function (cell) {
            return cell.innerText;
          },
          []
        );
      }
      return tempRows;
    };

    if (tableList) {
      var tableRows = [];
      if (Array.isArray(tableList)) {
        // Çoklu Tablo
        for (var i = 0; i < tableList.length; i++) {
          tableRows.push(getTable(tableList[i]));
        }
      } else {
        // Tek Tablo
        tableRows.push(getTable(tableList));
      }

      var fullTemplate = "";

      if (Array.isArray(tableRows)) {
        for (let i = 0; i < tableRows.length; i++) {
          if (i > 0) {
            fullTemplate +=
              "\n----------------------------------------------\n";
          }
          fullTemplate += tableRows[i];
        }
      }

      // Kopyala
      Web.ClipboardText(fullTemplate, false);
      return true;
    }
    return false;
  }
}

export class Animation {
  constructor() {}
  static Counter(sender, start = 0, end, duration = 1000, isMoney = false) {
    var startTimestamp = null;
    var step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      if (isMoney) {
        sender.innerText = C.Money(progress * (end - start) + start);
      } else {
        sender.innerText = C.InsertDot(
          Math.floor(progress * (end - start) + start)
        );
      }
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
}

//export const Tooltip = {
//    mounted: function (el, binding) {
//        var callback = function (isCreated) {
//            if (isCreated) {
//                var div = document.getElementById(el.tooltipId);
//                if (div) { div.remove(); }
//                el.tooltipId = null;
//            }
//            else {
//                el.tooltipId = Rnd.Id(50);
//                var div = document.createElement('div');
//                div.setAttribute('id', el.tooltipId);
//                div.classList.add('long-text');
//                div.classList.add('tooltip-text');
//                if (C.Str(el.getAttribute('text')) != '') { div.innerText = el.getAttribute('text'); }
//                else { div.innerHTML = el.getAttribute('html'); }
//                el.parentNode.insertBefore(div, el.nextSibling);
//            }
//        }

//        var target = [];
//        switch (C.Str(el.getAttribute('target'))) {
//            case 'click':
//                target = ['mousedown', 'mouseup'];
//                break;
//            case 'hover':
//            default:
//                target = ['mouseover', 'mouseout'];
//                break;
//        }
//        el.addEventListener(target[0], function () { callback(false); });
//        el.addEventListener(target[1], function () { callback(true); });
//    },
//};
