<template>
    <div class="filter-group col-lg-auto" :class="[ e.size ]">
        <label class="form-control-label fs-small-4" :for="e.controlId">{{ title }}</label>
        <template v-if="type == 'select'">
            <select class="form-select form-select-sm" v-bind="$attrs" :id="e.controlId" @change="setValue($event)">
                <slot :selected="getValue()">
                    <option :value="r[bindData]" v-for="r in e.list" :selected="C.Str(r[bindData]) == getValue()">{{r[bindText]}}</option>
                </slot>
            </select>
        </template>
        <template v-else-if="type == 'date'">
            <!--<input type="date" class="form-control form-control-sm" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" />-->
            <Datepicker class="form-control" :uid="e.controlId" v-bind="$attrs" :modelValue="getValue()" @update:modelValue="setValue($event)" :enable-time-picker="false" locale="tr" position="left" select-text="Seç" cancel-text="İptal" :clearable="false" format="dd.MM.yyyy"></Datepicker>
        </template>
        <template v-else-if="type == 'time'">
            <!--<input type="time" class="form-control form-control-sm" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" />-->
            <Datepicker class="form-control" :uid="e.controlId" v-bind="$attrs" :modelValue="getValue()" time-picker @update:modelValue="setValue($event)" locale="tr" enable-seconds position="left" select-text="Seç" cancel-text="İptal" :clearable="false" auto-apply format="HH:mm:ss"></Datepicker>
        </template>
        <template v-else-if="type == 'datetime'">
            <!--<input type="datetime-local" class="form-control form-control-sm" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" />-->
            <Datepicker class="form-control" :uid="e.controlId" v-bind="$attrs" :modelValue="getValue()" @update:modelValue="setValue($event)" locale="tr" position="left" select-text="Seç" cancel-text="İptal" :clearable="false" format="dd.MM.yyyy HH:mm"></Datepicker>
        </template>
        <template v-else-if="type == 'decimal'">
            <input type="number" class="form-control form-control-sm text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" min="0.0000" max="999999.0000" step="0.0001" />
        </template>
        <template v-else-if="type == 'money'">
            <div class="input-group input-group-sm">
                <span class="input-group-text">€</span>
                <input type="number" class="form-control form-control-sm text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" min="0.00" max="999999.00" step="0.01" />
            </div>
        </template>
        <template v-else-if="type == 'number'">
            <input type="number" class="form-control form-control-sm text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" min="0" max="999999" step="1" />
        </template>
        <template v-else-if="type == 'button'">
            <slot></slot>
        </template>
        <template v-else>
            <input type="text" class="form-control form-control-sm" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" />
        </template>
    </div>
</template>
<script>
    import { C, D } from "@/helpers/global";

    let _uid = 1;
    export default {
        name: "ControlFilter",
        inheritAttrs: false,
        components: {
        },
        props: {
            modelValue: {
            },
            title: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                required: true,
            },
            list: {
                type: Array,
                default: [],
            },
            size: {
                type: String,
                default: 'col-12'
            },
            bindData: {
                type: String,
                default: 'Id'
            },
            bindText: {
                type: String,
                default: 'Adi'
            },
        },
        emits: ['update:modelValue'],
        data() {
            return {
                e: {
                    controlId: null,
                    title: this.title,
                    value: this.modelValue,
                    list: this.list,
                    size: this.size,
                }
            }
        },
        watch: {
            title: function (n) {
                this.e.title = n;
            },
            modelValue: function (n) {
                this.e.value = n;
            },
            list: function (n) {
                this.e.list = n;
            },
            size: function (n) {
                this.e.size = n;
            }
        },
        methods: {
            getValue() {
                switch (this.type) { 
                    case 'select':
                        return C.Str(this.e.value);
                    case 'date':
                        return C.Date(this.e.value);
                    case 'time':
                        return C.Date(this.e.value);
                    case 'datetime':
                        return C.Date(this.e.value);
                    default:
                        return C.Str(this.e.value);
                }
            },
            setValue(event) {
                switch (this.type) {
                    case 'auto-select':
                        this.e.value = event;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'date':
                        this.e.value = event;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'time':
                        this.e.value = event;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'datetime':
                        this.e.value = event;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    default:
                        this.e.value = event.target.value;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                }
            }
        },
        mounted() {
            this.e.controlId = 'cf_' + _uid;
            _uid++;
        }
    };
</script>