<template>
  <div class="smart-table">
    <div class="row btn-filter" v-if="!t.filter.disable">
      <div class="col">
        <button class="form-control btn btn-primary" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseFilter_' + t.id" :aria-controls="'collapseFilter_' + t.id" aria-expanded="false" >
          <i class="pe-2 fa fa-filter"/>Filtreleme
        </button>
      </div>
    </div>
    <div class="filter collapse" :id="'collapseFilter_' + t.id" v-if="!t.filter.disable">
      <div class="row g-2 align-items-end">
        <div :class="[t.fullFilter ? 'col-lg-12' : 'col-lg-6']" class="col-md-12 align-items-start">
            <div class="row g-2">
                <slot name="left"></slot>
            </div>
        </div>
        <div :class="[t.fullFilter ? 'col-lg-12' : 'col-lg-6']" class="col-md-12 align-items-end">
          <div class="row g-2 justify-content-end">
            <div class="col-12 col-lg-auto" v-if="!t.filter.pagingSelectDisable">
              <div class="paging-select">
                <!--<label class="form-control-label fs-small-4">Sayfalama</label>-->
                <select class="form-select form-select-sm" @change="changePagingSelect" >
                  <option :value="r" v-for="r in t.filter.pagingList" :key="r" :selected="r == t.filter.pagingSelect" >{{ r }}</option>
                </select>
              </div>
            </div>
            <div class="col-6 col-lg-auto" v-if="!t.filter.searchDisable">
              <div class="search">
                <!--<label class="form-control-label fs-small-4">İçerik Arama</label>-->
                <input v-if="t.list.length > 1000" :value="t.filter.search" @input="searchClear($event)" @keyup.enter="t.filter.search = $event.target.value" type="search" class="form-control form-control-sm" placeholder="Arama yapınız..."/>
                <input v-if="t.list.length <= 1000" :value="t.filter.search" @input="t.filter.search = $event.target.value" type="search" class="form-control form-control-sm" placeholder="Arama yapınız..."/>
              </div>
            </div>
            <slot name="right"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <perfect-scrollbar class="col-12 table-wrapper">
          <!--<table :id="t.id" class="table table-hover table-striped table-bordered" :style="{ 'min-width': t.width }">-->
        <table :id="t.id" class="table table-hover" :style="{ 'min-width': t.width }">
          <thead>
            <tr>
              <th v-for="c in visibleColumns" :key="c" :class="columnCss(c)" :style="{ width: c.width }" @click="sortColumn($event)">{{ c.text }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in visibleRows" :key="item">
              <slot :r="item" :index="index"></slot>
            </template>
            <tr class="no-records-found" v-if="t.list.length > 0 && visibleRows.length == 0">
              <td :colspan="visibleColumns.length">Görüntülenecek Kayıt Yok</td>
            </tr>
            <tr class="no-records-found" v-if="t.list.length == 0">
              <td :colspan="visibleColumns.length">Kayıt Bulunamadı</td>
            </tr>
          </tbody>
          <tfoot>
                <slot name="footer" :data="visibleRows"></slot>
          </tfoot>
        </table>
      </perfect-scrollbar>
    </div>
    <div class="row" v-if="t.filter.pagingList.length > 0">
      <div class="col-sm-12 col-md-6">
        <div class="tables-info" role="status" aria-live="polite">
          <span>Görüntülenen: {{ (page.select - 1) * t.filter.pagingSelect + 1 }}-
            {{page.select * t.filter.pagingSelect > t.list.length ? t.list.length : page.select * t.filter.pagingSelect}}</span>
          <span> | Toplam Kayıt: {{ t.list.length }}</span>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
          <div class="page-number">
              <ul>
                  <li class="back" :class="{ disabled: page.select == 1 }">
                      <a href="javascript:void(0);" @click="page.select -= 1">&lt;</a>
                  </li>
                  <li v-if="page.select - 2 > 0"><a href="javascript:void(0);" @click="page.select -= 2">{{ page.select - 2  }}</a></li>
                  <li v-if="page.select - 1 > 0"><a href="javascript:void(0);" @click="page.select -= 1">{{ page.select - 1 }}</a></li>
                  <li class="active"><a href="javascript:void(0);">{{ page.select  }}</a></li>
                  <li v-if="page.select + 1 <= page.count"><a href="javascript:void(0);" @click="page.select += 1">{{ page.select + 1 }}</a></li>
                  <li v-if="page.select + 2 <= page.count"><a href="javascript:void(0);" @click="page.select += 2">{{ page.select + 2  }}</a></li>
                  <li class="next" :class="{ disabled: page.select == page.count }">
                      <a href="javascript:void(0);" @click="page.select += 1">&gt;</a>
                  </li>
              </ul>
        </div>
        <!--<nav aria-label="navigation">
          <ul class="pagination justify-content-end">
            <li class="page-item first" :class="{ disabled: page.select == 1 }">
              <a href="javascript:void(0);" class="page-link" @click="page.select = 1">
                <span aria-hidden="true">&laquo;&laquo;</span></a>
            </li>
            <li class="page-item previous" :class="{ disabled: page.select == 1 }">
              <a href="javascript:void(0);" class="page-link" @click="page.select -= 1">
                <span aria-hidden="true">&laquo;</span></a>
            </li>
            <li class="page-item">
              <input type="number" min="1" :max="page.count" v-model="page.select"/><span>/ {{ page.count }}</span>
            </li>
            <li class="page-item next" :class="{ disabled: page.select == page.count }">
              <a href="javascript:void(0);" class="page-link" @click="page.select += 1">
                <span aria-hidden="true">&raquo;</span></a>
            </li>
            <li class="page-item last" :class="{ disabled: page.select == page.count }">
              <a href="javascript:void(0);" class="page-link" @click="page.select = page.count">
                <span aria-hidden="true">&raquo;&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>-->
      </div>
    </div>
  </div>
</template>
<script>
    import { appData, C, Rnd, Text } from "@/helpers/global";

    import { PerfectScrollbar } from "vue3-perfect-scrollbar";

    export default {
        name: "SmartTable",
        init(j) {
            j.id = Rnd.Id(25);
            if (!j.filter) {
                j.filter = {};
            }
            if (j.filter.disable === undefined) {
                j.filter.disable = false;
            }
            if (j.filter.searchDisable === undefined) {
                j.filter.searchDisable = false;
            }
            if (j.filter.pagingSelectDisable === undefined) {
                j.filter.pagingSelectDisable = false;
            }
            if (!j.filter.search) {
                j.filter.search = "";
            }
            if (!j.filter.pageIndex) {
                j.filter.pageIndex = 1;
            }
            if (!j.filter.recordCount) {
                j.filter.recordCount = 0;
            }
            if (!j.filter.pagingList) {
                j.filter.pagingList = [10, 25, 50, 100];
            }
            if (!j.filter.pagingSelect) {
                j.filter.pagingSelect = 0;
            }

            if (!j.width) {
                j.width = "768px";
            }
            if (!j.columns) {
                j.columns = [];
            }
            if (!j.list) {
                j.list = [];
            }
            if (!j.fullFilter) {
                j.fullFilter = false;
            }
            return j;
        },
        components: {
            PerfectScrollbar,
        },
        props: {
            table: {
                type: Object,
            },
        },
        emits: ["count", "changePage"],
        watch: {
            table: {
                handler() {
                    this.load();
                },
                deep: true,
            },
        },
        data() {
            return {
                t: this.table,
                sortIndex: -1,
                sortAZ: false,
                page: {
                    select: 1,
                    count: 1,
                },
                reInit: true,
            };
        },
        methods: {
            load() {
                if (this.t.list == null) {
                    this.t.list = [];
                }

                if (this.reInit) {
                    if (this.t.filter.pagingList.length > 0) {
                        var x = C.Int(this.t.list.length / this.t.filter.pagingList[0]);
                        for (var i = this.t.filter.pagingList.length - 1; i >= 0; i--) {
                            if (x >= this.t.filter.pagingList[i]) {
                                this.t.filter.pagingSelect = this.t.filter.pagingList[i];
                                break;
                            }
                        }
                        if (x < this.t.filter.pagingList[0]) {
                            this.t.filter.pagingSelect = this.t.filter.pagingList[0];
                        }
                    }
                    else {
                        this.t.filter.pagingSelect = 0;
                    }
                    this.$emit("count", this.t.list.length);
                }
                this.reInit = true;
            },
            sortColumn(event) {
                var sender = event.target;
                if (sender) {
                    if (sender.classList.contains("no-sort")) {
                        sender.classList.remove("sort-up");
                        sender.classList.remove("sort-down");
                    } else {
                        var status = false;
                        if (sender.classList.contains("sort-down")) {
                            status = true;
                        } // A-Z
                        else if (sender.classList.contains("sort-up")) {
                            status = false;
                        } // Z-A
                        else {
                            status = false;
                        }
                        status = !status;

                        appData.each(
                            sender.closest("tr").querySelectorAll("th"),
                            function () {
                                this.classList.remove("sort-up");
                                this.classList.remove("sort-down");
                            }
                        );

                        sender.classList.add(status ? "sort-down" : "sort-up");
                        this.sortIndex = sender.cellIndex;
                        this.sortAZ = status;
                        //Sıralama Yap
                    }
                }
            },
            searchClear(event) {
                if (event.target.value == "") {
                    this.t.filter.search = "";
                }
            },
            searchData() {
                var _this = this;
                var l = [];
                if (this.t.filter.search != "") {
                    try {
                        var search = Text.TrFind(_this.t.filter.search).trim();
                        var searchColumns = _this.t.columns.filter((r) =>
                            C.Bool(r.search, true)
                        );

                        var findText = function (r, r2) {
                            var value;
                            if (r2.value) {
                                try {
                                    value = r2.value(r);
                                } catch (ex2) {
                                    console.log("Search Value Error:" + ex2.message);
                                }
                            } else {
                                try {
                                    value = r[r2.name];
                                } catch (ex2) {
                                    console.log("Search Name Error:" + ex2.message);
                                }
                            }

                            if (value) {
                                if (Text.TrFind(value).indexOf(search) > -1) {
                                    l.push(r);
                                    return true;
                                }
                            }
                            return false;
                        };

                        for (var i = 0; i < _this.t.list.length; i++) {
                            for (var j = 0; j < searchColumns.length; j++) {
                                if (findText(_this.t.list[i], searchColumns[j])) {
                                    break;
                                }
                            }
                        }
                    } catch (ex) {
                        console.error("Search Error:" + ex.message);
                    }
                } else {
                    l = this.t.list;
                }
                return l;
            },
            sortData(l) {
                var bubbleSort = function (c, azSort) {
                    try {
                        for (var i = 0; i < l.length; i++) {
                            for (var j = i + 1; j < l.length; j++) {
                                if (C.SortBig(l[i][c.name], l[j][c.name], azSort, c.type)) {
                                    var pivot = l[i];
                                    l[i] = l[j];
                                    l[j] = pivot;
                                }
                            }
                        }
                    } catch (ex) {
                        console.error("BubbleSort Error:" + ex.message);
                    }
                };
                //var quickSort = function (v1, v2, c) {
                //  var leftHoldValue = v1;
                //  var rightHoldValue = v2;
                //  var pivot = l[v1];

                //  while (v1 < v2) {
                //    while (
                //      C.SortBig(l[v2][c.name], pivot[c.name], true, c.type) &&
                //      v1 < v2
                //    ) {
                //      v2--;
                //    }
                //    if (v1 != v2) {
                //      l[v1] = l[v2];
                //      v1++;
                //    }
                //    while (
                //      !C.SortBig(l[v1][c.name], pivot[c.name], true, c.type) &&
                //      v1 < v2
                //    ) {
                //      v1++;
                //    }
                //    if (v1 != v2) {
                //      l[v2] = l[v1];
                //      v2--;
                //    }
                //  }

                //  l[v1] = pivot;
                //  pivot = v1;
                //  v1 = leftHoldValue;
                //  v2 = rightHoldValue;

                //  if (v1 < pivot) {
                //    quickSort(v1, pivot - 1, l);
                //  }
                //  if (v2 > pivot) {
                //    quickSort(pivot + 1, v2, l);
                //  }
                //};

                try {
                    if (
                        l.length > 0 &&
                        this.sortIndex > -1 &&
                        this.sortIndex < this.t.columns.length
                    ) {
                        var c = this.t.columns[this.sortIndex];
                        if (c.type == null) {
                            if (typeof l[0][c.name] === "number") {
                                c.type = "dec";
                            }
                            if (typeof l[0][c.name] === "object") {
                                if (!isNaN(l[0][c.name])) {
                                    if (Object.prototype.toString.call(l[0][c.name]) === "[object Date]") {
                                        c.type = "datetime";
                                    }
                                }
                            }
                        }
                        //if (l.length <= 100) {
                        bubbleSort(c, this.sortAZ);
                        //}
                        //else {
                        //    quickSort(0, l.length - 1, c);
                        //    if (!this.sortAZ) { l.reverse(); } // Z-A ya göre ise ters çevir
                        //}
                    }
                } catch (ex) {
                    console.error("Sort Error:" + ex.message);
                }
                return l;
            },
            pagingData(l) {
                var l2 = [];
                try {
                    if (this.t.filter.pagingList.length > 0) {
                        this.page.count = C.Int(l.length / this.t.filter.pagingSelect) + (l.length % this.t.filter.pagingSelect > 0 ? 1 : 0);

                        if (this.page.count <= 0) {
                            this.page.count = 1;
                        }
                        if (this.page.select > this.page.count) {
                            this.page.select = 1;
                        }

                        for (var i = (this.page.select - 1) * this.t.filter.pagingSelect;
                            i < this.page.select * this.t.filter.pagingSelect && i < l.length;
                            i++
                        ) {
                            l2.push(l[i]);
                        }
                        this.$emit("changePage", this.page.select);
                    }
                    else {
                        this.page.count = 1;
                        this.page.select = 1;
                        this.$emit("changePage", this.page.select);
                        l2 = l;
                    }
                    
                } catch (ex) {
                    console.error("Paging Error:" + ex.message);
                }
                return l2;
            },
            columnCss(c) {
                var l = [];
                if (c.css) {
                    if (c.css != "") {
                        l = C.Str(c.css).split(",");
                    }
                }
                if (!C.Bool(c.sort, true)) {
                    l.push("no-sort");
                }
                if (!C.Bool(c.export, true)) {
                    l.push("no-export");
                }
                return l;
            },
            changePagingSelect(event) {
                this.reInit = false;
                this.t.filter.pagingSelect = C.Int(event.target.value);
            },
        },
        computed: {
            visibleColumns() {
                var l = [];
                for (var i = 0; i < this.t.columns.length; i++) {
                    if (!C.Bool(this.t.columns[i].hide)) {
                        l.push(this.t.columns[i]);
                    }
                }
                return l;
            },
            filterData() {
                var l = this.searchData(); // Arama yap
                l = this.sortData(l); // Sıralama yap
                return l;
            },
            visibleRows() {
                var l = this.filterData;
                l = this.pagingData(l); // Sayfalama yap

                var _this = this;
                this.$nextTick(() => {
                    var table = document.getElementById(_this.t.id);
                    if (table) {
                        var cList = _this.visibleColumns;
                        var trList = table.querySelectorAll('tbody tr:not(.no-records-found):not(.child)');
                        for (var i = 0; i < trList.length; i++) {
                            var tdList = trList[i].querySelectorAll('td');
                            for (var j = 0; j < cList.length && j < tdList.length; j++) {
                                let b = document.createElement("b");
                                b.innerText = cList[j].text;
                                b.classList.add('th');
                                tdList[j].firstChild.before(b);
                            }

                        }
                    }
                })
                return l;
            },
        },
        created() {
            this.reInit = true;
            this.load();
        },
    };
</script>
