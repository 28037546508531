<template>
  <div class="content error">
    <PageError :page="page" :msg="msg"></PageError>
  </div>
</template>
<script>
    import PageError from "@/components/PageError.vue";

    export default {
        name: "ErrorView",
        components: {
            PageError,
        },
        props: {
            page: String,
            msg: String,
        },
    };
</script>
