<template>
  <header>
    <div class="row search-wrapper" :style="{ display: search.state ? 'flex' : 'none' }">
      <div class="col">
        <div class="search">
          <i class="fa fa-search"></i>
          <input type="text" placeholder="Aramak istediğiniz kelimeyi giriniz..." v-model="search.text" @keyup.enter="search.find"/>
        </div>
      </div>
      <div class="col button-close" style="max-width:100px">
        <button class="btn btn-primary" @click="search.state = false"><i class="fa fa-times"/></button>
      </div>
    </div>
    <div class="row header-nav" :style="{ display: search.state ? 'none' : 'flex' }">
      <div class="col">
        <div class="header-logo">
          <ul class="site-logo">
            <li>
              <!-- LOGO START -->
              <div class="logo">
                <router-link :to="Links.Get(Links.MuvekkilPanel.Anasayfa)" :title="appData.name" class="d-block">
                  <img src="/assets/img/logo.png" :alt="appData.name" />
                </router-link>
              </div>
              <!-- LOGO END -->
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary" @click="search.state = true">
          <i class="fa fa-search"></i>
        </button>
        <div class="search desktop">
          <i class="fa fa-search"></i>
          <input type="text" placeholder="Lütfen aramak istediğiniz kelimeyi giriniz..." v-model="search.text" @keyup.enter="search.find"/>
        </div>
      </div>
      <div class="col">
        <div class="navbar-right">
          <ul>
            <li class="btn-group">
              <div>
                <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown">
                  <div class="user-box">
                    <img :src="Web.Server(appData.customer.by.Profil, Web.Url(appData.default.profile))" :alt="C.Str(appData.customer.by.Isim)"/>
                  </div>
                  <span>{{ C.Str(appData.customer.by.Isim) }}</span>
                </button>
                <div class="dropdown-menu">
                  <!--<router-link :to="Links.Get(Links.MuvekkilPanel.Profil)" class="dropdown-item"><i class="profile"></i>Profil</router-link>-->
                  <div class="dropdown-divider"></div>
                  <a href="javascript:void(0)" @click="logout" class="dropdown-item"><i class="exit"></i>Çıkış</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
    import { reactive, toRefs, computed } from "vue";
    import { useRouter } from "vue-router";

    import { Links } from "@/helpers/enums";
    import { appData, C, J } from "@/helpers/global";
    import { Ajax, MethodType, Web } from "@/helpers/web";

    import { PerfectScrollbar } from "vue3-perfect-scrollbar";

    export default {
        components: {
            PerfectScrollbar,
        },
        setup() {
            const router = useRouter();
            const e = reactive({
                search: {
                    state: false,
                    text: "",
                    find() {
                        if (e.search.text.trim() != "") {
                            router.push(Links.Get(Links.MuvekkilPanel.Dosya.Listele) + '?search=' + e.search.text.trim());
                            e.search.text = '';
                        }
                    },
                },
                logout() {
                    appData.customer.logout();
                    Web.Redirect(appData.customer.loginPage);
                },

            });
            return { ...toRefs(e) };
        },
    };
</script>
