<template>
  <Teleport to="body">
    <div class="modal fade" v-bind="$attrs" :data-bs-backdrop="backdrop ? 'static' : false" :data-bs-keyboard="keyboard" :data-bs-focus="focus" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog" :class="size">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Ok</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script>
import { Modal } from "bootstrap";

export default {
  name: "SmartModal",
  inheritAttrs: false,
  init(sender) {
    return {
      show: function () {
        if (typeof sender === 'string') { sender = document.getElementById(sender); }
        var m = Modal.getOrCreateInstance(sender, {backdrop:'static', keyboard:false});
        //var m = Modal.getOrCreateInstance(sender, { keyboard: false });
        m.show();
      },
      hide: function () {
        if (typeof sender === 'string') { sender = document.getElementById(sender); }
        var m = Modal.getOrCreateInstance(sender);
        //var m = Modal.getOrCreateInstance(sender);
        m.hide();
      },
      showParent: function () {
          var m = Modal.getOrCreateInstance(sender.closest(".modal"), { backdrop: 'static', keyboard: false });
        //var m = Modal.getOrCreateInstance(sender.closest('.modal'), { keyboard: false });
        m.show();
      },
      hideParent: function () {
        var m = Modal.getOrCreateInstance(sender.closest(".modal"));
        //var m = Modal.getOrCreateInstance(sender.closest('.modal'));
        m.hide();
      },
    };
  },
  props: {
    title: String,
    backdrop: {
      type: Boolean,
      default: true,
    },
    keyboard: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default:
        "modal-dialog-centered modal-dialog-scrollable modal-fullscreen-md-down",
    },
  },
};
//props: ['p1', 'p2'] veya props: { p1: Boolean, p2: Number, p3: { type: String, required: true, default: 'Varsay�lan de�er' } },
</script>
