<template>
  <div v-if="!isLoaded" style="display: flex; justify-content: center" :style="{width: isPage ? '100vw' : '100%',height: isPage ? '100vh' : '100%'}">
    <img src="@/assets/svg/loading.svg" style="width: 20%" />
  </div>
</template>
<script>
export default {
  name: "PageLoader",
  props: {
    isPage: {
      type: Boolean,
      default: false,
    },
    isLoaded: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
