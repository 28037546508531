<template>
    <div>
        <PageLoader :isPage="true" :isLoaded="false" />
    </div>
</template>
<script>
    import { reactive, toRefs } from "vue";
    import { useRouter } from "vue-router";

    import { Links } from "@/helpers/enums";
    import { appData, C, J } from "../helpers/global";
    import { Web } from "@/helpers/web";

    import PageLoader from "@/components/PageLoader.vue";

    export default {
        name: "DefaultView",
        components: {
            PageLoader,
        },
        setup() {
            const router = useRouter();
            const e = reactive({
                detect() {
                    if (appData.customer.isRemember() || C.Str(sessionStorage.getItem("CustomerToken")) != '') {
                        if (C.Str(appData.customer.token) != "" && C.Int(appData.customer.by.Id) > 0) {
                            router.push(Links.Get(Links.MuvekkilPanel.Anasayfa));
                        }
                        else {
                            Web.Redirect(Links.Login);
                        }
                    }
                    else {
                        if (C.Str(appData.user.token) != "" && C.Int(appData.user.by.Id) > 0) {
                            router.push(Links.Get(Links.Anasayfa));
                        }
                        else {
                            Web.Redirect(Links.Login);
                        }
                    }
                }
            });
            return { ...toRefs(e) };
        },
        mounted() {
            this.detect();
        }
    };
</script>
