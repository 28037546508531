<template>
  <footer>
    <div class="row">
      <div class="col">
        <div class="footer-wrapper">
          <span class="footer-text">{{ new Date().getFullYear() }} Copyrights ©</span>
          <span class="footer-text blue"><a href="https://www.brunsia.com/" target="_blank">Brunsia Bilişim Ltd. Şti.</a></span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
