<template>
  <span ref="text">0</span>
</template>
<script>
import { Animation } from "@/helpers/web";

export default {
  name: "CounterAnimate",
  props: {
    value: {
      default: 0,
    },
    duration: {
      default: 2000,
    },
    isMoney: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: function (n) {
      this.val = n;
      this.fire();
    },
    duration: function (n) {
      this.wait = n;
      this.fire();
    },
  },
  data() {
    //Animation.Counter
    return {
      controlId: null,
      val: this.value,
      wait: this.duration,
      money: this.isMoney,
    };
  },
  methods: {
    fire() {
      Animation.Counter(this.$refs.text, 0, this.val, this.wait, this.money);
    },
  },
  mounted() {
    this.fire();
  },
};
</script>
