/* eslint-disable no-useless-escape */

"use strict";
import { reactive } from "vue";

// appData, C, Lang, Text, D, J, Rnd
export let appData = reactive({
    init: false,
    name: "App Name",
    Version: "1.00.000",
    cacheVersion: "",
    base: "",
    mediaBase: "",
    classicBase: "",
    serverBase: "",
    now: new Date(),
    dateDiff: 0,
    user: {
        token: "",
        loginPage: "/giris",
        by: { Id: 0 },
        isRemember: function () {
            return C.Str(localStorage.getItem("UserToken")) != '';
        },
        logout: function (all) {
            appData.user.token = "";
            appData.user.by = { Id: 0 };            
            localStorage.removeItem("UserToken");
            localStorage.removeItem("User");
            if (all) {
                localStorage.removeItem("LoginScreen");
                localStorage.removeItem("UserProfile");
                localStorage.removeItem("UserName");
                localStorage.removeItem("UserMail");
                localStorage.removeItem("UserFullName");
            }
            sessionStorage.removeItem("UserToken");
            sessionStorage.removeItem("User");
            sessionStorage.removeItem("UserProfile");
            sessionStorage.removeItem("UserName");
            sessionStorage.removeItem("UserMail");
            sessionStorage.removeItem("UserFullName");
        },
    },
    customer: {
        token: "",
        loginPage: "/muvekkil-panel/giris",
        by: { Id: 0 },
        isRemember: function () {
            return C.Str(localStorage.getItem("CustomerToken")) != '';
        },
        logout: function (all) {
            appData.customer.token = "";
            appData.customer.by = { Id: 0 };
            localStorage.removeItem("CustomerToken");
            localStorage.removeItem("Customer");
            if (all) {
                localStorage.removeItem("LoginScreen");
                localStorage.removeItem("CustomerProfile");
                localStorage.removeItem("CustomerName");
                localStorage.removeItem("CustomerMail");
                localStorage.removeItem("CustomerFullName");
            }
            sessionStorage.removeItem("CustomerToken");
            sessionStorage.removeItem("Customer");
            sessionStorage.removeItem("CustomerProfile");
            sessionStorage.removeItem("CustomerName");
            sessionStorage.removeItem("CustomerMail");
            sessionStorage.removeItem("CustomerFullName");
        },
    },
    list: {},
    page: {},
    lang: {
        Id: 1,
        List: [
            {
                Id: 1,
                Name: "Türkçe",
                Name2: "Turkish",
                ShortName: "tr",
                Culture: "tr-TR",
                Currency: "TL",
                Symbol: "₺",
                C1: "Lira",
                C2: "Kuruş",
                Rtl: false,
                Suffix: "_TR",
                IsMain: true,
            },
            //{ Id: 2, Name: "English", Name2: "English", ShortName: "en", Culture: "en-US", Currency: "USD", Symbol: "$", C1: "Dolar", C2: "Cent", Rtl: false, Suffix: "_EN" },
            //{ Id: 3, Name: "Deutsch", Name2: "German", ShortName: "de", Culture: "de-DE", Currency: "EUR", Symbol: "€", C1: "Dolar", C2: "Cent", Rtl: false, Suffix: "_DE"},
            //{ Id: 4, Name: 'عربي', Name2: 'Arabic', ShortName: 'ar', Culture: 'ar-SA', Currency: 'AED', Symbol: 'د.إ', C1: 'Dirhem', C2: 'Fils', Rtl: true, Suffix: '_AR' },
            //{ Id: 5, Name: 'Русский', Name2: 'Russian', ShortName: 'ru', Culture: 'ru-RU', Currency: 'RUB', Symbol: '₽', C1: 'Ruble', C2: 'Cent', Rtl: false, Suffix: '_RU' },
        ],
        Dictionary: { tr: [] },
    },
    default: {
        img: "/assets/img/empty.jpg",
        profile: "/assets/img/profile.png",
        logo: "/assets/img/logo.png",
        currency: function () {
            for (var i = 0; i < appData.lang.List.length; i++) {
                if (appData.lang.List[i].Id == appData.lang.Id) {
                    return appData.lang.List[i].Symbol;
                }
            }
            return "?";
        },
        msg: {
            noAccess: 'Yetkiniz bulunmamaktad\u0131r!',
            noRecord: 'Kay\u0131t bulunmamad\u0131!',
            noDeleteRecord: 'Silinecek kay\u0131t bulunamad\u0131!',
            noValidRecord: 'Bu kay\u0131t ge\u00E7erli de\u011Fil!',
            noConnect: '\u0130nternet Ba\u011Flant\u0131s\u0131 Yok!',
            dataSave: 'Bilgiler kaydedildi.',
            dataSaveFailed: 'Bilgiler kaydedilmedi!',
            dataDelete: 'Bilgiler silindi.',
            dataDeleteFailed: 'Bilgiler silinemedi!',
            dataGet: 'Bilgiler getirildi.',
            dataGetFailed: 'Bilgiler getirilemedi!',
            processFailed: '\u0130\u015Flem ba\u015Far\u0131s\u0131z!',
        }
        //NoAccess: 'Yetkiniz bulunmamaktad\u0131r!',
        //NoRecord: 'Kay\u0131t bulunmamad\u0131!',
        //NoDeleteRecord: 'Silinecek kay\u0131t bulunamad\u0131!',
        //NoValidRecord: 'Bu kay\u0131t ge\u00E7erli de\u011Fil!',
        //NoConnect: '\u0130nternet Ba\u011Flant\u0131s\u0131 Yok!',
        //DataSave: 'Bilgiler kaydedildi.',
        //DataSaveFailed: 'Bilgiler kaydedilmedi!',
        //DataDelete: 'Bilgiler silindi.',
        //DataDeleteFailed: 'Bilgiler silinemedi!',
        //DataGet: 'Bilgiler getirildi.',
        //DataGetFailed: 'Bilgiler getirilemedi!',
        //ProcessFailed: '\u0130\u015Flem ba\u015Far\u0131s\u0131z!',
    },
    create: function (h) {
        var d = document.createElement("DIV");
        d.innerHTML = h;
        return d.firstElementChild;
    },
    clone: function (p) {
        var s = p.cloneNode();
        for (var i = 0; i < p.childNodes.length; i++) {
            var e = p.childNodes[i];
            if (e.clone) {
                s.appendChild(e.clone());
            } else {
                s.appendChild(e.cloneNode());
            }
        }
        return s;
    },
    body: function () {
        return document.querySelector("body");
    },
    get: function (s) {
        if (typeof s == "string") {
            return document.querySelector(s);
        }
        return s;
    },
    gets: function (s) {
        if (typeof s == "string") {
            return document.querySelectorAll(s);
        }
        return s;
    },
    getId: function (e) {
        return document.querySelector("#" + e);
    },
    getName: function (n) {
        return document.querySelector('[name="' + n + '"]');
    },
    getNames: function (n) {
        return document.querySelectorAll('[name="' + n + '"]');
    },
    getJSON: function (u, c) {
        var x = new XMLHttpRequest();
        x.open("GET", u, true);
        x.responseType = "json";
        x.onload = function () {
            if (x.status === 200) {
                c(x.response, 200);
            } else {
                c(x.response, x.status);
            }
        };
        x.send();
    },
    getSCRIPT: function (u) {
        var s = document.createElement("script");
        s.src = u;
        s.type = "text/javascript";
        s.defer = false;
        document.getElementsByTagName("head").item(0).appendChild(s);
    },
    each: function (o, fn) {
        if (!o) {
            return o;
        }
        if (o.length > 0) {
            for (var i = 0; i < o.length; i++) {
                if (fn.call(o[i], i, o[i]) === false) {
                    break;
                }
            }
        } else if (o != null && o.length == undefined) {
            fn.call(o);
        }
        return o;
    },
    bus: {
        data: {},
        listener: [],
        init: function () {
            return Rnd.Id(50);
        },
        on: function (me, keys, callback, isSet) {
            if (isSet == undefined) {
                isSet = true;
            }
            if (typeof keys === "string") {
                keys = keys.split(",");
            }
            if (keys[0] != "") {
                if (isSet) {
                    this.listener.push({ me: me, code: me._bus, keys: keys, fnc: callback, set: true });
                }
                else {
                    this.listener.push({ code: me._bus, keys: keys, fnc: callback, set: false });
                }
            }
        },
        get: function (me, key, callback) {
            if (key != "") {
                this.listener.push({ code: me._bus, key: key, fnc: callback, set: false });
            }
        },
        emit: function (me, key, value, escape) {
            if (escape == undefined) {
                escape = true;
            }
            if (key != "") {
                this.data[key] = value;
                this.callListener(me._bus, key, escape);
            }
        },
        callListener: function (code, key, escape) {
            for (var i = 0; i < this.listener.length; i++) {
                var listen = this.listener[i];
                if (!escape && listen.code == code) {
                    // kendisinde çalışmaz
                    continue;
                }
                if (listen.keys.length > 0) {
                    for (var j = 0; j < listen.keys.length; j++) {
                        if (listen.keys[j] == key) {
                            if (listen.set) {
                                listen.me[key] = this.data[key];
                            }
                            if (listen.fnc) {
                                listen.fnc({ key: key, value: this.data[key] });
                            }
                            break;
                        }
                    }
                } else {
                    // single
                    listen.fnc(this.data[key]);
                }
            }
        },
    },
    initialize: function (args) {
        appData.name = args.name;
        appData.Version = args.version;
        appData.cacheVersion = args.cacheVersion;
        appData.mediaBase = args.mediaBase;

        //appData.base = args.base;
        //appData.classicBase = args.classicBase;
        appData.serverBase = args.base;
        appData.base = args.classicBase;

        appData.now = args.now;
        appData.dateDiff = D.DateDiff("second", new Date(), args.now);
        // Sistem Dili - Datayı Etkilemez
        appData.lang.Id = C.Int(args.langId, 1);
        appData.list.Lang = Lang.Detail(appData.lang.Id, true);

        if (appData.customer.isRemember() || C.Str(sessionStorage.getItem("CustomerToken")) != '') {
            if (appData.customer.isRemember()) {
                // Beni hatırla:Aktif
                appData.customer.token = C.Str(localStorage.getItem("CustomerToken"));
                appData.customer.by = J.Parse(localStorage.getItem("Customer"), '{}');
            } else {
                appData.customer.token = C.Str(sessionStorage.getItem("CustomerToken"));
                appData.customer.by = J.Parse(sessionStorage.getItem("Customer"), '{}');
            }
        }
        else {
            if (appData.user.isRemember()) {
                // Beni hatırla:Aktif
                appData.user.token = C.Str(localStorage.getItem("UserToken"));
                appData.user.by = J.Parse(localStorage.getItem("User"), '{}');
            } else {
                appData.user.token = C.Str(sessionStorage.getItem("UserToken"));
                appData.user.by = J.Parse(sessionStorage.getItem("User"), '{}');
            }
        }

        for (var key in args.default) {
            appData.default[key] = args.default[key];
        }
        //// Veri dili
        //appData.list.dil = {
        //    by: {},
        //    data: args.dilList,
        //    get(id) {
        //        for (var i = 0; i < appData.list.dil.data.length; i++) {
        //            if (id <= 0 && appData.list.dil.data[i].Ana) {
        //                return appData.list.dil.data[i];
        //            } else if (appData.list.dil.data[i].Id == id) {
        //                return appData.list.dil.data[i];
        //            }
        //        }
        //        return null;
        //    },
        //};
        //appData.list.dil.main = appData.list.dil.get(0);
    },
});

// Dönüşüm-Convert İşlemleri
export class C {
  constructor() {}
  static IsNumeric(v) {
    var t = typeof v;
    return ("number" === t || "string" === t) && !isNaN(v - parseFloat(v));
  }
  static IsDate(v) {
    //return typeof v.getMonth === 'function';
    return v instanceof Date;
  }
  static Str(v, def) {
    if (def == null) { def = ""; }
    if (v == null) {
      return def.toString();
    }
    else if (v.toString() == "") {
      return def.toString();
    }
    return v.toString();
  }
  static Bool(v, def) {
    if (def == null) { def = false; }
    try {
      if (v == null || C.Str(v) == "") {
        return def;
      }
      else {
        v = C.Str(v).toUpperCase();
        if (v == "FALSE") {
          return false;
        } else if (v == "0") {
          return false;
        } else if (v == "TRUE") {
          return true;
        } else if (v == "1") {
          return true;
        } else if (v == "OK") {
          return true;
        } else if (v == "YES") {
          return true;
        } else if (v == "ON") {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      console.log(e.message);
    }
    return def;
  }
  static Int(v, def) {
    if (def == null) { def = 0; }
    try {
      if (v == null || C.Str(v) == "") {
        return def;
      } else if (!C.IsNumeric(v)) {
        return def;
      } else {
        return parseInt(v, 10);
      }
    } catch (e) {
      console.log(e.message);
    }
    return def;
  }
  static DecF(v, def) {
    if (def == null) { def = 0; }
    try {
      if (v == null || C.Str(v) == "") {
        return def;
      } else if (!C.IsNumeric(v)) {
        return def;
      } else {
        return parseFloat(parseFloat(v).toFixed(4));
      }
    } catch (e) {
      console.log(e.message);
    }
    return def;
  }
  static Dec(v, def) {
    if (typeof v == "number") {
      return C.DecF(v, def);
    }
    return C.DecF(C.Str(v).replace(/\./g, "").replace(",", "."), def);
  }
  static Date(v, def) {
    try {
        if (def == null || isNaN(def)) { def = new Date("1900-1-1"); }
        if (v == null || C.Str(v).trim() == "" || isNaN(new Date(v))) {
            return def;
        }
        else if (C.IsDate(v)) {
            if (v.getFullYear() > 1900) {
                return v;
            }
            return def;
        }
        else {
            v = C.Str(v);
            try {
                if (v.indexOf("T") > -1 && v.indexOf("+") == -1) { v += "+00:00"; }
                
                if (new Date(v).getFullYear() > 1900) {
                    if (v.substring(0, 19).indexOf(".") <= -1) {
                        return new Date(v);
                    }
                } else {
                    return def;
                }
            } catch (e2) {
                console.log(e2.message);
            }
            
            var p, k;
            if (v.indexOf(".") > -1) {
                p = v.split(" ");
                k = p[0].split(".");
                if (k.length == 3) {
                    if (C.Int(k[2]) < 1000) {
                        k[2] = "1000";
                    }
                    v = k[2] + "-" + k[1] + "-" + k[0];
                    if (p.length == 2) {
                        v += " " + p[1];
                    }
                }
            } else {
                v = v.replace(/\//g, "-").replace(/\./g, "-");
                p = v.split(" ");
                k = p[0].split("-");
                if (k.length == 3) {
                    if (C.Int(k[0]) < 1000) {
                        k[0] = "1000";
                    }
                    v = k[0] + "-" + k[1] + "-" + k[2];
                    if (p.length == 2) {
                        v += " " + p[1];
                    }
                }
            }
            return new Date(v.replace(/\-/g, "/"));
        }
    } catch (e) {
        console.log(e.message);
    }
    return def;
}
  static Money(v, scale) {
      return C.MoneyF(C.Str(C.Dec(v)).replace(".", ","), scale);
  }
  static MoneyF(v, scale) {
    scale = C.Int(scale);
    if (scale < 2) {
      scale = 2;
    }
    v = C.Str(v);
    var minus = C.Str(v).indexOf("-");
    v = v.replace("+", "").replace("-", "");
    var prefix = minus == 0 ? "-" : "";

    var p = v.split(",");
    var cent = C.Fill("0", scale);
    if (p.length == 2) {
      cent = p[1].substr(0, scale);
      if (cent.length < scale) {
        cent += C.Fill("0", scale - cent.length);
      }
    }
    v = prefix + C.InsertDot(p[0].replace(/\./g, "")) + "," + cent;
    return v;
  }
  static Fill(v, count) {
    var box = "";
    for (var i = 0; i < count; i++) {
      box += v;
    }
    return box;
  }
  static PreFill(t, count, v) {
    t = C.Str(t);
    v = C.Str(v, "0");
    if (t.length < count) {
      t = C.Fill(v, count - t.length) + t;
    }
    return t;
  }
  static InsertDot(v) {
    v = C.Str(v);
    var box = "";
    var count = v.length;
    var x = count - 1;
    for (var i = 1; i <= count; i++) {
      box = v[x] + box;
      if (i % 3 == 0 && i < count) {
        box = "." + box;
      }
      x--;
    }
    return box;
  }
  static DoDouble(v) {
    // İki Hane Yapma
    v = C.Int(v);
    if (C.Int(v) < 10) {
      return "0" + C.Str(v);
    }
    return C.Str(v);
  }
  static DoTriple(v) {
    v = C.Int(v);
    if (v < 10) {
      return "00" + C.Str(v);
    } else if (v < 100) {
      return "0" + C.Str(v);
    }
    return C.Str(v);
  }
  static SortBig(a, b, aZ, type) {
    var r = false;
    switch (C.Str(type).toLowerCase().replace(/ı/g, "i")) {
      case "int":
        r = C.Int(a) >= C.Int(b);
        break;
      case "dec":
        r = C.Dec(a) >= C.Dec(b);
        break;
      case "date":
        r = D.DateMaxEqual(D.RemoveTime(a), D.RemoveTime(b));
        break;
      case "time":
        r = D.DateMaxEqual(D.RemoveDate(a), D.RemoveDate(b));
        break;
      case "datetime":
        r = D.DateMaxEqual(a, b);
        break;
      default:
        r = Text.BigString(a, b);
        break;
    }
    if (!aZ) {
      r = !r;
    }
    return r;
  }
  static SortSmall(a, b, aZ, type) {
    var r = false;
    switch (C.Str(type).toLowerCase().replace(/ı/g, "i")) {
      case "int":
        r = C.Int(a) <= C.Int(b);
        break;
      case "dec":
        r = C.Dec(a) <= C.Dec(b);
        break;
      case "date":
        r = D.DateMinEqual(D.RemoveTime(a), D.RemoveTime(b));
        break;
      case "time":
        r = D.DateMinEqual(D.RemoveDate(a), D.RemoveDate(b));
        break;
      case "datetime":
        r = D.DateMinEqual(a, b);
        break;
      default:
        r = !Text.BigString(a, b);
        if (C.Str(a) == C.Str(b)) {
          r = true;
        }
        break;
    }
    if (!aZ) {
      r = !r;
    }
    return r;
  }
  static ToFixed(v, scale) {
    if (scale == null) {
      scale = 2;
    }
    return C.DecF(v.toFixed(scale));
  }
  static IsEmptyArray(p) {
    if (Array.isArray(p)) {
      return p.length <= 0;
    }
    return true;
  }
}

// Dil İşlemleri
export class Lang {
  constructor() {}
  static Ready;
  static Initialize() {
    var callbackLoadLang = function (index) {
      index++;
      if (index < appData.lang.List.length) {
        var l = appData.lang.List[index];
        appData.getJSON(
          "/json/lang/" + l.ShortName + ".json?v=" + appData.cacheVersion,
          function (json, status) {
            if (status == 200) {
              appData.lang.Dictionary[l.ShortName] = json; //J.Parse(C.Str(json), '[]');
              callbackLoadLang(index);
            } else {
              console.error("Dil verileri getirelemedi!");
            }
          }
        );
      } else {
        if (Lang.Ready) {
          Lang.Ready();
        }
      }
    };
    callbackLoadLang(-1); // Load Start
  }
  static GetName(key, langName, def) {
    key = C.Str(key);
    if (key != "") {
      try {
        var groups = appData.lang.Dictionary[langName];
        for (var i = 0; i < groups.length; i++) {
          var groupName = C.Str(groups[i].G);
          var list = groups[i].L;
          if (list) {
            for (var j = 0; j < list.length; j++) {
              var kv = list[j];
              if (
                (groupName != "" ? groupName + "." : "") + C.Str(kv.K) ==
                key
              ) {
                return kv.V;
              }
            }
          }
        }
        //console.error('Lang:' + langName + ' - Key:' + key);
      } catch (e) {
        console.error(
          "Lang:" + langName + " - Key:" + key + " Ex:" + e.message
        );
      }
      return C.Str(def) != "" ? (def != "[empty]" ? def : "") : key;
    }
    return "";
  }
  static Get(key, langId, def) {
    key = C.Str(key);
    langId = C.Int(langId, appData.lang.Id);
    for (var i = 0; i < appData.lang.List.length; i++) {
      var l = appData.lang.List[i];
      if (C.Int(l.Id) == langId) {
        return Lang.GetName(key, l.ShortName, def);
      }
    }
    //console.error('Lang:' + langId + ' - Key:' + key);
    return "[Lang:" + langId + " - Key:" + key + "]";
  }
  static GetData(key, langId, def) {
    key = C.Str(key);
    if (key != "") {
      langId = C.Int(langId, appData.lang.Id);
      for (var i = 0; i < appData.lang.List.length; i++) {
        var l = appData.lang.List[i];
        if (C.Int(l.Id) == langId) {
          return Lang.GetName(key, l.ShortName, def);
        }
      }
    }
    return def;
  }
  static GetAll(key, def) {
    // Anahtar için tüm dillerdeki karşılığı getirir.
    var list = [];
    for (var i = 0; i < appData.lang.List.length; i++) {
      var l = appData.lang.List[i];
      list.push({
        Id: l.Id,
        Name: l.ShortName,
        Value: Lang.Get(key, l.Id, def),
      });
    }
    return list;
  }
  static Detail(langId) {
    for (var i = 0; i < appData.lang.List.length; i++) {
      var l = appData.lang.List[i];
      if (C.Int(l.Id) == langId) {
        return l;
      }
    }
    return null;
  }
  static Set(langId) {
    langId = C.Int(langId);
    for (var i = 0; i < appData.lang.List.length; i++) {
      var l = appData.lang.List[i];
      if (l.Id == C.Int(langId)) {
        appData.lang.Id = langId;
        break;
      }
    }
  }
  //static DataToObject(data, langId) {
  //  langId = C.Int(langId, appData.list.dil.main.Id);
  //  if (typeof data.Lang == "string") {
  //    data.Lang = J.Parse(data.Lang, "[]");
  //  }
  //  for (var i = 0; i < data.Lang.length; i++) {
  //    var r = data.Lang[i];
  //    if (r.DilId == langId) {
  //      for (const [key, value] of Object.entries(r)) {
  //        if (data[key] == undefined) {
  //          // Tanımsız ise ez
  //          data[key] = value;
  //        }
  //      }
  //      break;
  //    }
  //  }
  //  return data;
  //}
}

// Metinsel İşlemler
export class Text {
  constructor() {}
  static Rep(t, oldValue, newValue) {
    // Tekrarlı Replace İşlemi
    t = C.Str(t);
    oldValue = C.Str(oldValue);
    newValue = C.Str(newValue);

    if (newValue.indexOf(oldValue) > -1) {
      console.log("Text.Rep: Döngüsel hata!");
    }
    newValue = newValue.replace(/\$/g, "$$$");
    while (t.indexOf(oldValue) > -1) {
      t = t.replace(oldValue, newValue);
    }
    return t;
  }
  static MultiRep(t, old, changeList) {
    t = C.Str(t);
    var p1 = C.Str(old).split(",");
    if (p1.length == changeList.length) {
      for (var i = 0; i < p1.length; i++) {
        t = Text.Rep(t, "[" + C.Str(p1[i]).trim() + "]",C.Str(changeList[i]).replace(/\[/g, "[A]"));
      }
    }
    return t.replace(/\[A\]/g, "[");
  }
  static Tr(v) {
    return C.Str(v)
      .replace(/â/g, "a")
      .replace(/ç/g, "c")
      .replace(/ı/g, "i")
      .replace(/ğ/g, "g")
      .replace(/ş/g, "s")
      .replace(/ö/g, "o")
      .replace(/ü/g, "u")
      .replace(/Â/g, "A")
      .replace(/Ç/g, "C")
      .replace(/İ/g, "I")
      .replace(/Ğ/g, "G")
      .replace(/Ş/g, "S")
      .replace(/Ö/g, "O")
      .replace(/Ü/g, "U");
  }
  static ToUpper(v) {
    return C.Str(v).replace(/i/g, "İ").replace(/ı/g, "I").toUpperCase();
  }
  static ToLower(v) {
    return C.Str(v).replace(/I/g, "ı").replace(/İ/g, "i").toLowerCase();
  }
  static TrFind(v) {
    return Text.Tr(v).toLowerCase().replace(/ı/g, "i");
  }
  static In(v, p) {
    v = C.Str(v);
    if (p == null) { p = []; }
    if (typeof p == "string") {
      p = p.split(",");
    }
    for (var i = 0; i < p.length; i++) {
      if (v == C.Str(p[i])) {
        return true;
      }
    }
    return false;
  }
  static InList(l, p) {
    if (typeof l == "string") {
      l = l.split(",");
    }
    for (var i = 0; i < l.length; i++) {
      if (Text.In(l[i], p)) {
        return true;
      }
    }
    return false;
  }
  static InIndex(v, p) {
    v = C.Str(v);
    if (p == null) {
      p = [];
    }
    if (typeof p == "string") {
      p = p.split(",");
    }
    for (var i = 0; i < p.length; i++) {
      if (v == C.Str(p[i])) {
        return i;
      }
    }
    return -1;
  }
  static GetIndex(v, p) {
    v = C.Int(v);
    if (p == null) {
      p = [];
    }
    if (typeof p == "string") {
      p = p.split(",");
    }
    if (v >= 0 && v < p.length) {
      return p[v];
    }
    return "-";
  }
  static Summary(v, max, extra) {
    // Özet - Kelime ve Harf Bazlı
    var v2 = "";
    if (v != null) {
      var p = C.Str(v).split(" ");
      for (var i = 0; i < p.length; i++) {
        if (v2.length + 1 + p[i].length < C.Int(max)) {
          v2 += " " + p[i];
        } else {
          v2 += C.Str(extra);
          break;
        }
      }
    }
    return v2;
  }
  static WordCount(v) {
    // Kelime Sayıcı
    v = C.Str(v)
      .trim()
      .replace(/\n/g, " ")
      .replace(/\r/g, " ")
      .replace(/\t/g, " ");
    var count = 0;
    for (var i = 0; i < v.length; i++) {
      if (v[i] == " ") {
        count++;
        for (var j = i + 1; j < v.length; j++) {
          if (v[j] != " ") {
            i = j - 1;
            break;
          }
        }
        continue;
      }
    }
    if (v == "") {
      return 0;
    }
    return count + 1;
  }
  static BigString(v1, v2) {
    // Metin büyüklük kontrolü
    v1 = Text.ToUpper(Text.Tr(v1));
    v2 = Text.ToUpper(Text.Tr(v2));
    for (var i = 0; i < v1.length && i < v2.length; i++) {
      if (v1[i] != v2[i]) {
        if (v1[i].charCodeAt() > v2[i].charCodeAt()) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (v1.length < v2.length) {
      return false;
    }
    return true;
  }
  static HtmlBr(value) {
    return C.Str(value).replace(/\r\n/g, "<br>").replace(/\n/g, "<br>");
  }
  static ToLowerEn(v) {
    return C.Str(v).toLowerCase().replace(/ı/g, "i");
  }
  static Prefix(t, count, v) {
    v = C.Str(v, " ");
    for (var i = 0; i < count; i++) {
      t = v + t;
    }
    return t;
  }
  static Suffix(t, count, v) {
    v = C.Str(v, " ");
    for (var i = 0; i < count; i++) {
      t = t + v;
    }
    return t;
  }
  static IfIndex(index) {
    var l = [];
    if (arguments.length > 1) {
      if (Array.isArray(arguments[1])) {
        l = arguments[1];
      } else {
        for (var i = 1; i < arguments.length; i++) {
          l.push(arguments[i]);
        }
      }
    }
    if (index < 0 || index >= l.length) {
      index = 0;
    }
    if (l.length > 0) {
      return l[index];
    }
    return "-";
  }
  static EscapeHtml(v) {
    var keys = [
      "ş",
      "Ş",
      "ı",
      "İ",
      "ç",
      "Ç",
      "ö",
      "Ö",
      "ü",
      "Ü",
      "Ğ",
      "ğ",
      "â",
      "Â",
    ];
    var values = [
      "&#351;",
      "&#350;",
      "&#305;",
      "&#304;",
      "&ccedil;",
      "&Ccedil;",
      "&ouml;",
      "&Ouml;",
      "&uuml;",
      "&Uuml;",
      "&#286;",
      "&#287;",
      "&#226;",
      "&#194;",
    ];
    for (var i = 0; i < keys.length; i++) {
      v = v.replaceAll(keys[i], values[i]);
    }
    return v;
    }
  static Format(v) {
      v = C.Str(v);
      var attr = [];
      for (var i = 1; i < arguments.length; i++) {
          attr.push(C.Str(arguments[i]));
      }
      
      for (var i = 0; i < attr.length; i++) {
          v = v.replaceAll("{" + i + "}", attr[i]);
      }
      return v;
  }
}

// Tarih İşlemleri
export class D {
  constructor() {}
  static IsDate(v) {
    //return typeof v.getMonth === 'function';
    return v instanceof Date;
    //try {
    //  //return (typeof v.getMonth === 'function');
    //  if (!isNaN(v)) {
    //    if (Object.prototype.toString.call(v) === "[object Date]") {
    //      return true;
    //    }
    //  }
    //} catch (e) { console.log(e.message); }
    //return false;
  }
  static ServerNow() {
    return D.DateAdd("second", appData.dateDiff, new Date());
  }
  static MaxDate(isBig) {
    if (isBig) {
        return D.DateAdd('year', 20, new Date());
    }
    return D.DateAdd('year', 2, new Date());
  }
  static MinDate(isBig) {
      if (isBig) {
          return new Date(2000, 1, 1);
      }
      return new Date((new Date().getFullYear() - 1), 1, 1);
  }
  static DateFormat(v, isTimeZone) {
    var d = C.Date(v);
    if (isTimeZone == undefined || isTimeZone) {
        return (d.getFullYear() + "-" + C.DoDouble(d.getMonth() + 1) + "-" + C.DoDouble(d.getDate()) + "T00:00:00+00:00");
    }
    return (d.getFullYear() + "-" + C.DoDouble(d.getMonth() + 1) + "-" + C.DoDouble(d.getDate()));
  }
    static TimeFormat(v, isTimeZone) {
      var d = C.Date(v);
      if (isTimeZone == undefined || isTimeZone) {
          return "T" + C.DoDouble(d.getHours()) + ":" + C.DoDouble(d.getMinutes()) + ":" + C.DoDouble(d.getSeconds() + "+00:00");
      }
       return C.DoDouble(d.getHours()) + ":" + C.DoDouble(d.getMinutes()) + ":" + C.DoDouble(d.getSeconds());
  }
  static DateTimeFormat(v) {
    return D.DateFormat(v, false) + D.TimeFormat(v);
  }
  static DateToString(v) {
      var d = C.Date(v);
      return (C.DoDouble(d.getDate()) + "." + C.DoDouble(d.getMonth() + 1) + "." + d.getFullYear());
  }
  static TimeToString(v, isSecond) {
      var d = C.Date(v);
      if (isSecond) {
          return C.DoDouble(d.getHours()) + ":" + C.DoDouble(d.getMinutes()) + ":" + C.DoDouble(d.getSeconds());
      }
      else {
          return C.DoDouble(d.getHours()) + ":" + C.DoDouble(d.getMinutes());
      }
  }
  static DateTimeToString(v, isSecond) {
      return D.DateToString(v) + " " + D.TimeToString(v, isSecond);
  }
  static StartMonthDay(v) {
    return new Date(v.getFullYear(), v.getMonth(), 1);
  }
  static EndMonthDay(v) {
    return new Date(v.getFullYear(), v.getMonth() + 1, 0);
  }
  static StartYearDay(v) {
    return new Date(v.getFullYear(), 0, 1); // Ocak 1
  }
  static EndYearDay(v) {
    return new Date(v.getFullYear(), 11, 31); // Aralık 31
  }
  static DateDiff(part, d1, d2) {
    d1 = C.Date(d1);
    d2 = C.Date(d2);
    var t1, t2;
    switch (part.toLowerCase()) {
      case "year":
        var years = d2.getFullYear() - d1.getFullYear();
        return years;
      case "month":
        var months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months += d2.getMonth() - d1.getMonth();
        return months;
      case "day":
        t2 = d2.getTime();
        t1 = d1.getTime();
        return parseInt((t2 - t1) / (24 * 3600 * 1000));
      case "hour":
        t2 = d2.getTime();
        t1 = d1.getTime();
        return C.Dec(((t2 - t1) / (3600 * 1000)).toFixed(2));
      case "minute":
        t2 = d2.getTime();
        t1 = d1.getTime();
        return parseInt((t2 - t1) / (60 * 1000));
      case "second":
        t2 = d2.getTime();
        t1 = d1.getTime();
        return parseInt((t2 - t1) / 1000);
    }
  }
  static DatePart(part, d1) {
    d1 = C.Date(d1);
    switch (part.toLowerCase()) {
      case "year":
        return d1.getFullYear();
      case "month":
        return d1.getMonth() + 1;
      case "day":
        return d1.getDate();
      case "hour":
        return d1.getHours();
      case "minute":
        return d1.getMinutes();
      case "second":
        return d1.getSeconds();
    }
    return null;
  }
  static GetYear(d1) {
    return D.DatePart("year", d1);
  }
  static GetMonth(d1) {
    return D.DatePart("month", d1);
  }
  static GetDay(d1) {
    return D.DatePart("day", d1);
  }
  static DateAdd(part, v, d1) {
    var date = new Date(C.Date(d1).valueOf());
    if (v == 0) {
      return date;
    }
    switch (part.toLowerCase()) {
      case "year":
        date.setFullYear(date.getFullYear() + v);
        return date;
      case "first-day-year":
        date.setFullYear(date.getFullYear() + v);
        return D.StartYearDay(date);
      case "end-day-year":
        date.setFullYear(date.getFullYear() + v);
        return D.EndYearDay(date);
      case "month":
        {
          var endDate = D.StartMonthDay(date);
          endDate.setMonth(endDate.getMonth() + v);
          endDate = D.EndMonthDay(endDate);
          if (endDate.getDate() < date.getDate()) {
            date.setDate(endDate.getDate());
          }
        }
        date.setMonth(date.getMonth() + v);
        return date;
      case "first-day-month":
        date = D.StartMonthDay(date);
        date.setMonth(date.getMonth() + v);
        return date;
      case "end-day-month":
        date = D.StartMonthDay(date);
        date.setMonth(date.getMonth() + v);
        return D.EndMonthDay(date);
      case "day":
        date.setDate(date.getDate() + v);
        return date;
      case "hour":
        date.setHours(date.getHours() + v);
        return date;
      case "minute":
        date.setMinutes(date.getMinutes() + v);
        return date;
      case "second":
        date.setSeconds(date.getSeconds() + v);
        return date;
    }
    return null;
  }
  static DateEqual(d1, d2) {
    return D.DateToString(d1) == D.DateToString(d2);
  }
  static DateMinEqual(d1, d2) {
    if (D.DateToString(d1) != D.DateToString(d2)) {
      return C.Date(d1) < C.Date(d2);
    }
    return true;
  }
  static DateMaxEqual(d1, d2) {
    if (D.DateToString(d1) != D.DateToString(d2)) {
      return C.Date(d1) > C.Date(d2);
    }
    return true;
  }
  static TimeEqual(d1, d2, isSecond) {
    return D.TimeToString(C.Date(d1), isSecond) == D.TimeToString(C.Date(d2), isSecond);
  }
  static DateTimeEqual(d1, d2) {
    return D.DateTimeToString(d1) == D.DateTimeToString(d2);
  }
  static RemoveDate(v, date) {
    if (date == null) { date = new Date(); }
    return C.Date(D.DateFormat(date, false) + D.TimeFormat(v));
  }
  static RemoveTime(v) {
        return C.Date(D.DateFormat(v));
  }
  static HourToTime(totalHours) {
    totalHours = C.Int(totalHours);
    var hour = totalHours % 24;
    var totalDay = C.Int((totalHours - hour) / 24);
    var day = totalDay % 7;
    var week = C.Int((totalDay - day) / 7);
    var result = "";
    if (week > 0) {
      if (result != "") {
        result += " ";
      }
      result += week + " Hafta";
    }
    if (day > 0) {
      if (result != "") {
        result += " ";
      }
      result += day + " Gün";
    }
    if (hour > 0) {
      if (result != "") {
        result += " ";
      }
      result += hour + " Saat";
    }
    return result;
  }
  static IsAM(d1) {
    var time = C.Int(D.TimeToString(C.Date(d1)).replace(":", ""));
    return time < 1200;
  }
  static IsPM(d1) {
    var time = C.Int(D.TimeToString(C.Date(d1)).replace(":", ""));
    return time > 1200;
  }
  static GetYearList(minYear, maxYearDown) {
    var d = D.ServerNow();
    var year = d.getFullYear() - C.Int(maxYearDown); // Bu yıldan -X yıl öncesi için
    var list = [];
    for (var i = year; i >= minYear; i--) {
      list.push(i);
    }
    return list;
  }
}

// Json işlemleri
export class J {
  static Clone(v) {
    return JSON.parse(JSON.stringify(v));
  }
  static Parse(v, def) {
    try {
      return JSON.parse(C.Str(v, def));
    } catch (e) {
      console.log(e.message);
    }
    return JSON.parse(def);
  }
  static String(data, def) {
    try {
      return JSON.stringify(data);
    } catch (e) {
      console.log(e.message);
    }
    return def;
  }
    static IsFind(list, data, findName) {
    if (findName == null) {
      findName = "Id";
    }
    for (var i = 0; i < list.length; i++) {
      var r = list[i];
      if (findName == "") {
        if (C.Str(r) == C.Str(data)) {
          return true;
        }
      } else {
        if (C.Str(r[findName]) == C.Str(data)) {
          return true;
        }
      }
    }
    return false;
  }
  static Find(list, data, findName) {
    if (findName == null) {
      findName = "Id";
    }
    for (var i = 0; i < list.length; i++) {
      var r = list[i];
      if (findName == "") {
        if (C.Str(r) == C.Str(data)) {
          return r;
        }
      } else {
        if (C.Str(r[findName]) == C.Str(data)) {
          return r;
        }
      }
    }
    return null;
  }
  static FindId(list, data, name, def) {
    var findName = "Id";
    name = C.Str(name, "Adi");
    def = C.Str(def, "-");
    var r = J.Find(list, data, findName);
    if (r != null) {
      //for (var key in r) {
      //    if (r.hasOwnProperty(key)) {
      //        var $$v = r[key];
      //        eval('var ' + key + ' = $$v;');
      //    }
      //}
      try {
        //return C.Str(eval(name), def);
        return C.Str(r[name], def);
      } catch (e) {
        console.log(e.message);
      }
    }
    return def;
  }
  static PrintFormat(list, data, format, findName, def) {
    if (findName == null) {
      findName = "Id";
    }
    var r = J.Find(list, data, findName);
    if (r != null) {
      for (var key in r) {
        if (Object.prototype.hasOwnProperty.call(r, key)) {
          format = Text.Rep(format, "{" + key + "}", r[key]);
        }
      }
      return format;
    }
    return C.Str(def, "");
  }
  static ToIndex(list, name) {
    var id = "Id";
    name = C.Str(name, "Adi");
    var l = [];
    for (var i = 0; i < list.length; i++) {
      var p = {};
      p[id] = i;
      p[name] = list[i];
      l.push(p);
    }
    return l;
  }
  static ToName(list, name) {
    var id = "Id";
    name = C.Str(name, "Adi");
    var l = [];
    for (var i = 0; i < list.length; i++) {
      var p = {};
      p[id] = list[i];
      p[name] = list[i];
      l.push(p);
    }
    return l;
  }
  static Remove(list, data) {
    for (var i = list.length - 1; i >= 0; i--) {
      var r = list[i];
      if (r == data) {
        list.splice(i, 1);
      }
    }
  }
  static RemoveId(list, data, findName) {
    if (findName == null) {
      findName = "Id";
    }
    for (var i = list.length - 1; i >= 0; i--) {
      var r = list[i];
      if (findName == "") {
        if (C.Str(r) == C.Str(data)) {
          list.splice(i, 1);
        }
      } else {
        if (C.Str(r[findName]) == C.Str(data)) {
          list.splice(i, 1);
        }
      }
    }
  }
  static Filter(list, where) {
    var l = [];
    for (var i = 0; i < list.length; i++) {
      var $r = list[i];
      if (where($r)) {
        l.push(J.Clone($r));
      }
    }
    return l;
  }
  static Distinct(list, names) {
    var l = [];
    names = C.Str(names).split(",");
    for (var i = 0; i < list.length; i++) {
      var r = list[i];
      var isIn = false;
      for (var j = 0; j < l.length; j++) {
        var r2 = l[j];
        var isInIndex = 0;
        for (let k = 0; k < names.length; k++) {
          let key = names[k];
          if (r2[key] == r[key]) {
            isInIndex++;
          }
        }
        if (isInIndex == names.length) {
          isIn = true;
          break;
        }
      }
      if (!isIn) {
        var o = {};
        for (let k = 0; k < names.length; k++) {
          let key = names[k];
          o[key] = r[key];
        }
        l.push(o);
      }
    }
    return l;
  }
  static Order(list, az, name) {
    az = C.Bool(az); // A-dan Zye
    var x = null,
      y = null;
    var isName = C.Str(name) != "";
    for (var i = 0; i < list.length; i++) {
      if (isName) {
        x = list[i][name];
      } else {
        x = list[i];
      }
      for (var j = i + 1; j < list.length; j++) {
        if (isName) {
          y = list[j][name];
        } else {
          y = list[j];
        }
        var change = false;
        if (typeof x == "string") {
          change = az ? Text.BigString(x, y) : !Text.BigString(x, y);
        } else if (D.IsDate(x)) {
          change = az ? !D.DateMinEqual(y, x) : !D.DateMaxEqual(y, x);
        } else {
          change = az ? C.Dec(x) > C.Dec(y) : C.Dec(x) < C.Dec(y);
        }
        if (change) {
          var box = list[i];
          list[i] = list[j];
          list[j] = box;
        }
      }
    }
    return list;
  }
  static Insert(list, data, index) {
    index = C.Int(index);
    var l = [];
    for (var i = 0; i < list.length; i++) {
      if (i == index) {
        l.push(data);
      }
      l.push(list[i]);
    }
    if (index == list.length) {
      l.push(data);
    }
    return l;
  }
  static InsertSelect(list, text, textKey) {
        var j = { Id: 0 };
        j[C.Str(textKey, 'Adi')] = text;
        return J.Insert(list, j, 0);
  }
  static AddKeyId(list) {
    var l = J.Parse(C.Str(list), "[]");
    for (var i = 0; i < l.length; i++) {
      l[i].Id = Rnd.Id(50);
    }
    return l;
  }
  static RemoveKey(list, name) {
    var l = [];
    for (var i = 0; i < list.length; i++) {
      var r = J.Clone(list[i]);
      l.push(r);
      delete l[l.length - 1][name];
    }
    return l;
  }
  static RemoveKeyId(list) {
    //
    var l = [];
    for (var i = 0; i < list.length; i++) {
      var r = J.Clone(list[i]);
      l.push(r);
      delete l[l.length - 1]["Id"];
    }
    return l;
  }
  static KeyFirstUpper(json) {
    var j;
    if (Array.isArray(json)) {
      j = [];
      for (var i = 0; i < json.length; i++) {
        j.push(J.KeyFirstUpper(json[i]));
      }
      return j;
    } else {
      j = {};
      for (var key in json) {
        if (Object.prototype.hasOwnProperty.call(json, key)) {
          var key2 = key.charAt(0).toUpperCase() + key.slice(1);
          j[key2] = json[key];
        }
      }
    }
    return j;
  }
}

//Randomize İşlemleri
export class Rnd {
  static Id(size) {
    return Rnd.Text(size, false, true);
  }
  static Text(size, isUpper, isId) {
    isId = C.Bool(isId);
    var text = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    if (C.Bool(isUpper)) {
      possible = possible.toUpperCase().replace(/i/g, "I");
    }
    for (var i = 0; i < size; i++) {
      var c = possible.charAt(Math.floor(Math.random() * possible.length));
      if (isId) {
        if (C.IsNumeric(c)) {
          i -= 1;
          continue;
        }
      }
      text += c;
    }
    return text;
  }
  static Number(size, isZero) {
    isZero = C.Bool(isZero);
    var text = "";
    var possible = "0123456789";
    for (var i = 0; i < size; i++) {
      var c = possible.charAt(Math.floor(Math.random() * possible.length));
      if (i == 0) {
        if (isZero && c == "0") {
          i -= 1;
          continue;
        }
      }
      text += c;
    }
    return text;
  }
  static MakeId(size) {
    // Rasgele Id Oluştur
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < size; i++) {
      var c = possible.charAt(Math.floor(Math.random() * possible.length));
      if (C.IsNumeric(c)) {
        c = "A";
      }
      text += c;
    }
    return text;
  }
}

export const LangText = function (el, binding) {
  var value = "";
  if (Object.prototype.toString.call(binding) === "[object String]") {
    value = binding;
  } else {
    value = binding.value;
  }

  var langId = C.Int(el.getAttribute("lang-id"), appData.lang.Id);
  el.innerHTML = Lang.Get(value, langId, el.getAttribute("lang-def"));
};
