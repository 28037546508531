<template>
  <div class="layout-theme emtpy">
    <main class="content-wrapper">
      <slot></slot>
    </main>
  </div>
</template>
<script>
export default {
  name: "LayoutEmpty",
};
</script>
