<template>
  <div class="d-inline-block dropdown">
    <button type="button" class="btn btn-primary dropdown-toggle" id="btnExportMenu" data-bs-toggle="dropdown" aria-expanded="false">
      <!--<span class="btn-icon-wrapper pe-2 opacity-7"></span>--><i class="fa fa-file-export pe-2"/><span>Export</span>
    </button>
    <div tabindex="-1" role="menu" class="dropdown-menu dropdown-menu-end" aria-labelledby="btnExportMenu">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link" @click="exportData('excel')">
            <i class="nav-link-icon fa fa-file-excel"/>
            <span>Excel Aktar</span>
          </a>
        </li>
        <!--<li class="nav-item">
                    <a class="nav-link" @click="exportData('cvs')">
                        <i class="nav-link-icon" icon="fa fa-file-csv" />
                        <span>Csv Aktar</span>
                    </a>
                </li>-->
        <!--<li class="nav-item">
                    <a disabled="" class="nav-link disabled="" "  @click="exportData('pdf')">
                        <i class="nav-link-icon fa fa-file-pdf" />
                        <span>Pdf Aktar</span>
                    </a>
                </li>-->
        <li class="nav-item">
          <a class="nav-link" @click="exportData('print')">
            <i class="nav-link-icon fa fa-print" />
            <span>Yazdır</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="exportData('copy')">
            <i class="nav-link-icon fa fa-copy" />
            <span>Kopyala</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { Exporter } from "@/helpers/web";

export default {
  name: "ExportButtons",
  props: ["tables"],
  watch: {
    tables: function (n) {
      this.tableList = n;
    },
  },
  data() {
    return {
      tableList: this.tables,
    };
  },
  methods: {
    exportData(type) {
      var _this = this;
      if (this.tableList) {
        if (typeof this.tableList === "string") {
          this.tableList = document.getElementById(this.tableList);
        }
        switch (type) {
          case "excel":
            if (Exporter.ToExcel(_this.tableList)) {
              _this.$swal({
                position: "top-end",
                icon: "success",
                title: "İstediginiz Excel İndirildi",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            break;
          case "print":
            if (Exporter.ToPrint(_this.tableList)) {
              _this.$swal({
                position: "top-end",
                icon: "success",
                title: "Çıktınız  Başarıyla Tamamlanmıştır",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            break;
          //case 'cvs':
          //    if (Exporter.ToCvs(_this.tableList)) {
          //        _this.$swal({
          //            position: 'top-end',
          //            icon: 'success',
          //            title: 'İstediginiz Cvs İndirildi',
          //            showConfirmButton: false,
          //            timer: 1500,
          //        });
          //    }
          //    break;
          //case 'pdf':
          //    if (Exporter.ToPdf(_this.tableList)) {
          //        _this.$swal({
          //            position: 'top-end',
          //            icon: 'success',
          //            title: 'İstediginiz Pdf İndirildi',
          //            showConfirmButton: false,
          //            timer: 1500,
          //        });
          //    }
          //    break;
          case "copy":
            if (Exporter.ToCopy(_this.tableList)) {
              _this.$swal({
                position: "top-end",
                icon: "success",
                title: "Kopyalama Başarıyla Tamamlandı",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            break;
        }
      }
    },
  },
};
</script>
