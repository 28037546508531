<template>
  <template v-if="anim">
    <span class="currency">{{ appData.default.currency() }}</span>
    <CounterAnimate
      :isMoney="true"
      :value="val"
      v-if="val"
      class="me-1"
    ></CounterAnimate>
  </template>
  <template v-else>
    <span class="me-1">{{ C.Money(val) }}</span>
    <span class="currency">{{ appData.default.currency() }}</span>
  </template>
</template>
<script>
import CounterAnimate from "./CounterAnimate.vue";

export default {
  components: {
    CounterAnimate,
  },
  name: "CurrencyUnit",
  props: {
    value: {
      default: 0,
    },
    animation: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: function (n) {
      this.val = n;
    },
    animation: function (n) {
      this.anim = n;
    },
  },
  data() {
    return {
      val: this.value,
      anim: this.animation,
    };
  },
};
</script>
