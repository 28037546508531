<template>
  <div class="layout-theme customer">
    <LayoutHeader></LayoutHeader>
    <main class="content-wrapper">
        <perfect-scrollbar class="scrollbar-sidebar">
            <slot></slot>
            <LayoutFooter />
        </perfect-scrollbar>
    </main>   
  </div>
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import LayoutHeader from "./LayoutCustomerHeader.vue";
import LayoutFooter from "./LayoutCustomerFooter.vue";

export default {
  name: "LayoutClassic",
  components: {
    PerfectScrollbar,

    LayoutHeader,
    LayoutFooter,
  },
};
</script>
