<template>
  <div class="header">
    <span class="title">500 Error</span>
    <span class="sub-title">General Server Error</span>
  </div>
  <div class="footer">
    <span class="description">{{ msg }}</span>
  </div>
  <!--<div class="footer">
        <router-link class="btn btn-primary" :to="Links.Get(Links.Anasayfa)">Anasayfaya Git</router-link>
        <a class="btn btn-primary" @click="$router.back()">Geri Dön</a>
    </div>-->
</template>
<script>
export default {
  name: "PageError",
  props: {
    page: String,
    msg: String,
  },
  setup() {},
};
</script>
