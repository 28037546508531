<template>
  <div class="content not-found">
    <PageNotFound></PageNotFound>
  </div>
</template>
<script>
    import PageNotFound from "@/components/PageNotFound.vue";

    export default {
        name: "NotFoundView",
        components: {
            PageNotFound,
        },
    };
</script>
