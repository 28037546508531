<template>
  <div class="header">
    <span class="title">404</span>
    <span class="sub-title">Page Not Found</span>
  </div>
  <div class="footer">
    <span class="description"
      >We're sorry, the page you requested could not be found.</span
    >
  </div>
  <!--<div class="footer">
        <router-link class="btn btn-primary" :to="Links.Get(Links.Anasayfa)">Anasayfaya Git</router-link>
        <a class="btn btn-primary" @click="$router.back()">Geri Dön</a>
    </div>-->
</template>
<script>
export default {
  name: "PageNotFound",
  setup() {},
};
</script>
