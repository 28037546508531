<template>
  <div class="layout-theme classic">
    <LayoutHeader></LayoutHeader>
    <LayoutSidebar></LayoutSidebar>
    <main class="content-wrapper">
      <perfect-scrollbar class="scrollbar-sidebar">
        <slot></slot>
        <LayoutFooter />
      </perfect-scrollbar>
    </main>
  </div>
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import LayoutHeader from "./LayoutClassicHeader.vue";
import LayoutSidebar from "./LayoutClassicSidebar.vue";
import LayoutFooter from "./LayoutClassicFooter.vue";

export default {
  name: "LayoutClassic",
  components: {
    PerfectScrollbar,

    LayoutHeader,
    LayoutSidebar,
    LayoutFooter,
  },
};
</script>
